<div *ngIf="events?.length === 0 && canDisplayNoResultPage" class="card border-0 bg-secondary">
  <div class="card-body">
    <h4 class="card-title">Nicio inregistrare</h4>
    <p class="card-text">Ne pare rau, nu a fost gasit niciun rezultat care sa corespunda cautarii.</p>
  </div>
</div>

<div *ngIf="events?.length" class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
  <div *ngFor="let x of events | slice: (page - 1) * itemsPerPage : page * itemsPerPage" class="col">
    <div class="card border-0 bg-secondary">
      <a [routerLink]="['/eveniment', x.code]">
        <img class="card-img-top" [src]="x.image" [alt]="x.name">
      </a>
      <div class="translate-middle-y text-center">
        <ngx-gauge class="event-progress" [type]="gaugeType" [value]="!x.targetAmount ? 100 : x.targetProgress < 100 ? x.targetProgress : 100"
          [append]="gaugeAppendText" [thick]="thick" [size]="70" [backgroundColor]="backgroundColor"
          [foregroundColor]="foregroundColor" cap="round">
        </ngx-gauge>
      </div>
      <div class="card-body pt-0 pb-4 mt-n4">
        <h3 class="h5 card-title text-center text-truncate"><a [routerLink]="['/eveniment', x.code]">{{x.name}}</a>
        </h3>
        <div class="row gx-0">
          <div class="col-6 text-center">
            <i [ngbTooltip]="'S-a contribuit cu ' + x.totalContributionAmount + ' lei' + (!x.hasTargetAmountHidden && !!x.targetAmount ? ' din targetul de ' + (x.targetAmount | number:'1.0-0') + ' lei': '')"
              class="ai-gift text-primary fs-4 fw-semibold"></i>
            <div class="text-truncate">{{x.totalContributionAmount | number:'1.0-0'}}<ng-container
                *ngIf="!x.hasTargetAmountHidden && !!x.targetAmount">/<span>{{x.targetAmount |
                  number:'1.0-0'}}</span></ng-container>
              <strong> LEI</strong>
            </div>
          </div>
          <div class="col-6 text-center">
            <i [ngbTooltip]="(x.status === 'Active' ? 'Au mai ramas ' + (x.remainingDays) + ' zile' : 'Evenimentul s-a incheiat')"
              class="ai-time text-primary fs-4 fw-semibold"></i>
            <div class="text-truncate">{{x.status === 'Active' ? x.remainingDays : 0}}<strong> ZILE</strong></div>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex align-items-center justify-content-between text-decoration-none pt-3">
        <ng-container *ngIf="canShowAdminButtons">
          <span class="d-flex"
            [ngbTooltip]="x.status === 'Active' ? 'Modifica' : 'Eveniment incheiat, nu mai poate fi modificat'">
            <button [routerLink]="['/user/creare-eveniment', x.code]" [disabled]="x.status !== 'Active'"
              class="btn btn-outline-primary btn-sm m-1" type="button"><i class="ai-edit me-1"></i>
            </button>
          </span>
          <span class="d-flex"
            [ngbTooltip]="x.status === 'Active' ? 'Sterge' : 'Eveniment incheiat, nu mai poate fi sters'">
            <button (click)="onDelete(x)" [disabled]="x.status !== 'Active'" class="btn btn-outline-danger btn-sm m-1"
              type="button"><i class="ai-trash me-1"></i>
            </button>
          </span>
        </ng-container>
        <span *ngIf="canShowContributeButton" class="d-inline-flex w-100"
          [ngbTooltip]="x.status === 'Active'? x.isSuspended ? 'Evenimentul este suspendat' : 'Contribuie' : 'Evenimentul s-a incheiat'">
          <button [routerLink]="['/contributie', x.code]" [disabled]="x.status !== 'Active' || x.isSuspended"
            class="btn btn-primary btn-sm w-100" type="button"><i class="ai-card me-1"></i>Contribuie
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Pagination-->
<div *ngIf="canDisplayPagination && events?.length" class="d-sm-flex align-items-center pt-4 pt-sm-5">
  <nav class="order-sm-2 ms-sm-auto mb-4 mb-sm-0" aria-label="Orders pagination">
    <ngb-pagination [(page)]="page" [collectionSize]="events.length" [pageSize]="itemsPerPage" [maxSize]="5"
      [directionLinks]="false" [boundaryLinks]="false" [rotate]="true" size="sm" (pageChange)="onPageChanged()"
      class="d-flex justify-content-center"></ngb-pagination>
  </nav>
</div>
