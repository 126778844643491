
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InviteService {

  constructor(private http: HttpClient) {
  }

  createContact(contact: any) {
    return this.http.post<any>(`${this.endpoint}`,
      contact);
  }

  updateContact(id: number, contact: any) {
    return this.http.put<any>(`${this.endpoint}/${id}`, contact);
  }

  deleteContact(id: number) {
    return this.http.delete<any>(`${this.endpoint}/${id}`);
  }

  importContacts(model: any) {
    return this.http.post<any>(`${this.endpoint}/import`, model);
  }

  inviteSend(model: any) {
    return this.http.post<any>(`${this.endpoint}/send`, model);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/invites`;
  }
}
