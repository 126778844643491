
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CouponService {

  constructor(private http: HttpClient) {
  }

  use(code: string) {
    return this.http.put<any>(`${this.endpoint}/use`, { code: code });
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/coupons`;
  }
}
