
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(
    private toastrService: ToastrService,
    private router: Router,
  ) { }

  /**
   * Themes:default, material, bootstrap
   * Type:default,info, success, wait, error, warning
   *
   * @param {string} title
   * @param {string} msg
   * @param {string} type
   * @memberof ToastService
   */
  show(title: string, msg: string, type: string) {
    switch (type) {
      case 'default': this.toastrService.info(msg, title); break;
      case 'info': this.toastrService.info(msg, title); break;
      case 'success': this.toastrService.success(msg, title); break;
      case 'error': this.toastrService.error(msg, title); break;
      case 'warning': this.toastrService.warning(msg, title); break;
    }
  }

  checkErrorForStatus(error) {
    switch (error.status) {
      case 404: {
        this.router.navigate(['/not-found']);
        break;
      }
      case 401:
      case 403: {
        break;
      }
      default: {
        this.router.navigate(['/error']);
      }
    }
  }
}
