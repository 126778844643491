<div class="modal-header border-0">
  <h4 class="modal-title">{{title}}</h4>
  <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-0" novalidate autocomplete="off">
  <div class="modal-body">
    <div class="row row-cols-1 g-4">
      <div class="col">
        <label class="form-label fs-base" for="name">Nume</label>
        <input formControlName="name" [ngClass]="{'is-invalid': vs.hasErrors(form, 'name')}"
          class="form-control form-control-lg" type="text" placeholder="ex. Ana Popescu" id="name" autofocus>
        <div *ngIf="vs.hasErrors(form, 'name')" class="invalid-feedback">{{vs.getError(form, 'name')}}</div>
      </div>
      <div class="col">
        <label class="form-label fs-base" for="email">Email</label>
        <input formControlName="email" [ngClass]="{'is-invalid': vs.hasErrors(form, 'email')}"
          class="form-control form-control-lg" type="email" placeholder="ex. ana.popescu@gmail.com" id="email">
        <div *ngIf="vs.hasErrors(form, 'email')" class="invalid-feedback">{{vs.getError(form, 'email')}}
        </div>
      </div>
      <div class="col">
        <label class="form-label fs-base" for="message">Descriere</label>
        <textarea formControlName="message" [ngClass]="{'is-invalid': vs.hasErrors(form, 'message')}"
          class="form-control form-control-lg" rows="6" placeholder="ex. Am introdus codul cardului cadou, dar primesc mesajul 'Cod invalid' desi cardul este activ."
          id="message"></textarea>
        <div *ngIf="vs.hasErrors(form, 'message')" class="invalid-feedback">{{vs.getError(form, 'message')}}
        </div>
      </div>
      <div class="col">
        <label class="form-label fs-base" for="captcha">Verificare de securitate</label>
        <re-captcha formControlName="captcha" [siteKey]="recaptchaSiteKey" [theme]="theme$ | async" size="normal"
          (resolved)="onCaptchaResolved($event)" (errored)="onCaptchaError($event)"></re-captcha>
        <div *ngIf="vs.hasErrors(form, 'captcha')" class="invalid-feedback">{{vs.getError(form,
          'captcha')}}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button (click)="close()" class="btn btn-secondary w-100 w-sm-auto mb-3 mb-sm-0" type="button"
      data-bs-dismiss="modal">Anuleaza</button>
    <button class="btn btn-primary w-100 w-sm-auto ms-sm-3" type="submit">Trimite</button>
  </div>
</form>
