import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { forkJoin, Observable, ReplaySubject, throwError } from 'rxjs';
import { ScriptModel } from '../../core/models';

export const ScriptStore: ScriptModel[] = [
  { name: 'jspdf', src: 'https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js' },
  { name: 'jspdf.autotable', src: 'https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/3.0.9/jspdf.plugin.autotable.min.js' },
  { name: 'xlsx', src: 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.14.4/xlsx.core.min.js' }
];

@Injectable({
  providedIn: 'root',
})
export class DynamicScriptLoaderService {

  loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: Document) { }

  loadMultipleScriptsByName(...names: string[]): Observable<any> {
    return forkJoin(names.map(x => this.loadScriptByName(x)));
  }

  loadMultipleScripts(...scripts: string[]): Observable<any> {
    return forkJoin(scripts.map(x => this.loadScript(x)));
  }

  /**
   * Load by stored name
   *
   * @param {string} name
   * @returns {Observable<void>}
   * @memberof DynamicScriptLoaderService
   */
  loadScriptByName(name: string): Observable<void> {
    const script = ScriptStore.find(x => x.name.toUpperCase() == name.toUpperCase());
    if (!script) {
      throw throwError("Script does not exist.");
    }

    return this.loadScript(script.src);
  }

  /**
   * Load script dynamically
   *
   * @param {string} url
   * @returns {Observable<void>}
   * @memberof DynamicScriptLoaderService
   */
  loadScript(url: string): Observable<void> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable();
    }

    this.loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = () => {
      this.loadedLibraries[url].next();
      this.loadedLibraries[url].complete();
    };

    script.onerror = (error: any) => {
      this.loadedLibraries[url].error("Could not load script " + url);
      this.loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this.loadedLibraries[url].asObservable();
  }

  /**
   * Load stylesheet dynamically
   *
   * @param {string} url
   * @returns {Observable<void>}
   * @memberof DynamicScriptLoaderService
   */
  loadStyle(url: string): Observable<void> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable();
    }

    this.loadedLibraries[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.rel = 'stylesheet';
    style.href = url;
    style.onload = () => {
      this.loadedLibraries[url].next();
      this.loadedLibraries[url].complete();
    };

    style.onerror = (error: any) => {
      this.loadedLibraries[url].error("Could not load script " + url);
      this.loadedLibraries[url].complete();
    };

    this.document.body.appendChild(style);

    return this.loadedLibraries[url].asObservable();
  }
}
