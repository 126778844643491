<div class="modal-header border-0">
  <h4 class="modal-title">{{title}}</h4>
  <button (click)="onClose()" class="btn-close" type="button" aria-label="Close"></button>
</div>
<div class="modal-body">
  <p [innerHTML]="description"></p>
</div>
<div class="modal-footer border-0">
  <button (click)="onClose()" class="btn w-100 w-sm-auto mb-3 mb-sm-0" [class]="rejectClass" (click)="onReject()"
    type="button">{{rejectLabel}}</button>
  <button class="btn w-100 w-sm-auto ms-sm-3" [class]="acceptClass" (click)="onAccept()"
    type="submit">{{acceptLabel}}</button>
</div>
