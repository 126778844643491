import { environment } from "../../../environments/environment";
import { UserProfile } from "../models";
import { isCorporateUser } from "./auth-utils";

export function userImage(image: string): string {
  return image ? environment.contentUrl + '/user-images/' + image : environment.userDefaultImageUrl;
}

export function eventImage(event: any): string {
  return event?.image ? environment.contentUrl + '/event-images/' + event.image
    : environment.contentUrl + '/event-type-images/' + event.eventTypeImage;
}

export function corporateEventImage(event: any): string {
  return event?.image ? environment.contentUrl + '/event-images/' + event.image
    : environment.contentUrl + '/corporate-image-templates/' + event.imageTemplateUrl;
}

export function voucherImage(image: string): string {
  return image ? environment.contentUrl + '/voucher-images/' + image : '';
}

export function giftCardImageTemplate(image: string): string {
  return image ? environment.contentUrl + '/giftcard-image-templates/' + image : null;
}

export function corporateImageTemplate(image: string): string {
  return image ? environment.contentUrl + '/corporate-image-templates/' + image : null;
}

export function userDashboardUrl(user: UserProfile): string {
  return isCorporateUser(user) ? '/corporate/dashboard' : '/user/dashboard';
}
