import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMPTY, Subscription, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { CouponService } from '../../../core/services/coupon.service';
import { ToastService } from '../../../core/services/toast.service';
import { AuthActions } from '../../../state';
import { ValidationService } from '../../../core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gs-coupon-modal',
  templateUrl: 'coupon-modal.component.html'
})
export class CouponModalComponent implements OnDestroy {
  title: string;

  form: FormGroup = this.formBuilder.group({
    code: ['', [Validators.required, Validators.pattern(/\d{5}-\d{5}$/)]],
    hasAcceptedCouponTerms: [false, [Validators.requiredTrue]],
  });

  private subscriptions: Subscription[] = [];

  constructor(
    private bsModalRef: NgbActiveModal,
    private couponService: CouponService,
    private toastService: ToastService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private store: Store,
    public vs: ValidationService
  ) {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.couponService.use(this.form.value.code)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return EMPTY;
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        ).subscribe(() => {
          this.store.dispatch(new AuthActions.GetCurrentUser());
          this.form.reset();
          this.toastService.show(
            'Succes',
            'Cardul GiftShare a fost aplicat cu succes.',
            'success'
          );
          this.close();
        })
    );
  }

  close() {
    this.bsModalRef.close();
  }

}
