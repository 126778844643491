import { Dictionary } from "./dictionary.model";
import { UserProfile } from "./user.model";

export interface Notification {
  id: number;
  content: string;
  type: string;
  referenceId: number;
  metadata: Dictionary<string>;
  user: UserProfile;
  viewDate?: Date;
  readDate?: Date;
  createdDate?: Date;
  updatedDate?: Date;
}
