import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  constructor(private http: HttpClient) {
  }

  getVouchers(): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}`);
  }

  getVoucherBySlug(slug: string) {
    return this.http.get<any>(`${this.endpoint}/${slug}`);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/vouchers`;
  }
}
