export interface Deposit {
  id: number;
  code: string;
  userId: number;
  amount: number;
  status: number;
  walletStatus: number;
  createdDate: Date;
  refundDate: Date;
}
