import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ValidationService {

  constructor() { }

  /**
   * Returns true if control has been touched or dirty and has specific validation errors.
   *
   * @param {(FormGroup | FormArray)} form
   * @param {string} property
   * @param {string} error
   * @returns {boolean}
   * @memberof ValidationService
   */
  hasError(form: FormGroup | FormArray, property: string, error: string): boolean {
    return (form.controls[property].dirty || form.controls[property].touched)
      && form.controls[property].hasError(error);
  }

  /**
   * Returns true if control has been touched or dirty and has validation errors.
   *
   * @param {(FormGroup | FormArray)} form
   * @param {string} property
   * @returns {boolean}
   * @memberof ValidationService
  */
  hasErrors(form: FormGroup | FormArray, property: string): boolean {
    return (form.controls[property].dirty || form.controls[property].touched)
      && form.controls[property].errors;
  }

  /**
   * Returns true if form has been touched or dirty and has a specific validation error.
   *
   * @param {(FormGroup | FormArray)} form
   * @param {string} error
   * @returns {boolean}
   * @memberof ValidationService
   */
  hasFormError(form: FormGroup | FormArray, error: string): boolean {
    return (form.dirty || form.touched)
      && form.hasError(error);
  }

  /**
   * Returns true if form has been touched or dirty and has validation errors.
   *
   * @param {(FormGroup | FormArray)} form
   * @param {string} property
   * @returns {boolean}
   * @memberof ValidationService
  */
  hasFormErrors(form: FormGroup | FormArray): boolean {
    return (form.dirty || form.touched)
      && !!form.errors;
  }

  /**
   * Checks only if the control has the specific error without considering dirty/touched states
   *
   * @param {(FormGroup | FormArray)} form
   * @param {string} property
   * @param {string} error
   * @returns {boolean}
   * @memberof ValidationService
   */
  hasBasicError(form: FormGroup | FormArray, property: string, error: string): boolean {
    return form.controls[property].hasError(error);
  }

  /**
   *  Returns true if form has a specific validation error.
   *
   * @param {(FormGroup | FormArray)} form
   * @param {string} error
   * @returns {boolean}
   * @memberof ValidationService
   */
  hasBasicFormError(form: FormGroup | FormArray, error: string): boolean {
    return form.hasError(error);
  }

  /**
   * Returns true if form has been touched or dirty and is invalid
   *
   * @param {(FormGroup | FormArray)} form
   * @returns {boolean}
   * @memberof ValidationService
   */
  hasInvalidControls(form: FormGroup | FormArray): boolean {
    return Object.keys(form.controls).some(key => (form.controls[key].dirty || form.controls[key].touched)
      && form.controls[key].errors);
  }

  /**
   * Returns a default error message for each validator type.
   *
   * @param {string} validatorName
   * @param {*} [validatorValue]
   * @returns {string}
   * @memberof ValidationService
   */
  getError(form: FormGroup | FormArray, property: string): string {
    const firstErrorKey = Object.keys(form.controls[property].errors)[0];
    return firstErrorKey ? this.buildErrorMessage(firstErrorKey, form.controls[property].errors[firstErrorKey]) : null;
  }

  /**
   * Returns a default error message for each validator type.
   *
   * @param {string} validatorName
   * @param {*} [validatorValue]
   * @returns {string}
   * @memberof ValidationService
   */
  getFormError(form: FormGroup | FormArray): string {
    const firstErrorKey = Object.keys(form.errors)[0];
    return firstErrorKey ? this.buildErrorMessage(firstErrorKey, form.errors[firstErrorKey]) : null;
  }

  private buildErrorMessage(validatorName: string, validatorValue?: any): string {
    const errorMessages = {
      required: 'Campul este obligatoriu.',
      whitespace: 'Campul nu poate contine doar spatii.',
      email: 'Formatul email-ului este incorect.',
      minlength: `Minimum ${validatorValue.requiredLength} caractere permise.`,
      maxlength: `Maximum ${validatorValue.requiredLength} caractere permise.`,
      min: `Valoarea minima este ${validatorValue.min}.`,
      max: `Valoarea maxima este ${validatorValue.max}.`,
      pattern: `Formatul este incorect.`,
      requiredUpper: `Parola trebuie sa contina cel putin o litera mare.`,
      requiredLower: `Parola trebuie sa contina cel putin o litera mica.`,
      requiredNumber: `Parola trebuie sa contina cel putin o cifra.`,
      requiredLength: `Parola trebuie sa contina minimum 8 caractere.`,
      requireNonAlphanumeric: `Parola trebuie sa contina un caracter special: $, #, @, ?, !, -.`,
      matchPassword: `Parola nu se potriveste.`,
      futureDate: `Data introdusa trebuie sa fie ulterioara datei curente.`
    };
    return errorMessages[validatorName] || 'Campul nu este valid.';
  }

  /**
   * Trigger FormControl validation by marking them as touched
   *
   * @param {FormGroup} formGroup
   * @memberof ValidationService
   */
  triggerValidation(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.triggerValidation(control);
      } else if (control instanceof FormArray) {
        this.triggerValidation(control);
      }
    });

    formGroup.markAsTouched();
  }

  /**
   * Get all form errors
   *
   * @param {(FormGroup | FormArray)} form
   * @returns {any[]}
   * @memberof ValidationService
   */
  getFormErrors(form: FormGroup | FormArray): any[] {
    let errors: any[] = [];
    Object.keys(form.controls).forEach(controlKey => {
      const control = form.controls[controlKey];
      if (control instanceof FormGroup || control instanceof FormArray) {
        errors = errors.concat(this.getFormErrors(control));
      } else {
        // Get all control errors
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(errorKey => {
            errors.push({
              control: controlKey,
              errorName: errorKey,
              errorValue: controlErrors[errorKey]
            });
          });
        }
      }

    });

    // Get form errors
    const formErrors: ValidationErrors = form.errors;
    if (formErrors) {
      Object.keys(formErrors).forEach(errorKey => {
        errors.push({
          control: 'form',
          errorName: errorKey,
          errorValue: formErrors[errorKey]
        });
      });
    }

    return errors;
  }

  /**
   * Trim form control values
   *
   * @param {FormGroup} formGroup
   * @memberof ValidationService
   */
  cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(
      (key) => {
        const control = formGroup.get(key);
        if (control.value) {
          control.setValue(control.value.trim());
        }
      }
    );
  }
}
