import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  NgbAccordionModule, NgbCollapseModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbDropdownModule,
  NgbModalConfig,
  NgbModalModule,
  NgbNavModule,
  NgbOffcanvasModule, NgbPaginationModule, NgbPopoverModule, NgbTimeAdapter, NgbTimepickerConfig, NgbTimepickerModule, NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ClipboardModule } from 'ngx-clipboard';
import { FacebookModule } from 'ngx-facebook';
import { NgxGaugeModule } from 'ngx-gauge';
import { ToastrModule } from 'ngx-toastr';
import player from 'lottie-web';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SwiperModule } from 'swiper/angular';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { ro } from 'date-fns/locale';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { YouTubePlayerModule } from '@angular/youtube-player';

import {
  AddressModalComponent,
  AvatarComponent,
  BillingAddressModalComponent,
  BlankLayoutComponent,
  ConfirmComponent,
  CookieBannerComponent,
  CouponModalComponent,
  DepositModalComponent,
  EventListComponent,
  FeedbackModalComponent,
  FooterComponent,
  HeaderComponent,
  HomeButtonComponent,
  MainLayoutComponent,
  ProfileModalComponent,
  ScrollButtonComponent,
  SecondaryLayoutComponent,
  YoutubeComponent
} from './components';
import {
  DecimalInputDirective, DigitOnlyDirective, FocusFirstInvalidInputDirective,
  RegexInputDirective, ValidateOnChangeDirective, UppercaseDirective
} from './directives';
import { DataFilterPipe, SafePipe } from './pipes';
import { CustomNgbDateParserFormatter, CustomNgbTimeAdapter } from './adapters';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { RECAPTCHA_LOADER_OPTIONS, RecaptchaFormsModule, RecaptchaLoaderOptions, RecaptchaModule } from 'ng-recaptcha';

/*
Do declare components, directives, and pipes in a shared module when those items will be re-used and referenced by the components
declared in other feature modules.
Do import all modules required by the assets in the SharedModule; for example, CommonModule and FormsModule.
Do declare all components, directives, and pipes in the SharedModule.
Do export all symbols from the SharedModule that other feature modules need to use.
Avoid specifying app-wide singleton providers in a SharedModule. Intentional singletons are OK.

*/

// Add shared pipes to this list
export const sharedPipes = [
  DataFilterPipe,
  SafePipe,
];

// Add shared directives to this list
export const sharedDirectives = [
  DecimalInputDirective,
  ValidateOnChangeDirective,
  DigitOnlyDirective,
  FocusFirstInvalidInputDirective,
  RegexInputDirective,
  UppercaseDirective,
];

// Add shared components to this list
export const sharedComponents = [
  ConfirmComponent,
  CouponModalComponent,
  DepositModalComponent,
  FeedbackModalComponent,
  EventListComponent,
  MainLayoutComponent,
  SecondaryLayoutComponent,
  BlankLayoutComponent,
  HeaderComponent,
  FooterComponent,
  ScrollButtonComponent,
  HomeButtonComponent,
  AvatarComponent,
  CookieBannerComponent,
  YoutubeComponent,
  ProfileModalComponent,
  AddressModalComponent,
  BillingAddressModalComponent
];

const romanianConfig = new DateFnsConfigurationService();
romanianConfig.setLocale(ro);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    DateFnsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      closeButton: true,
      enableHtml: true,
      // toastClass: 'toast'
    }),
    LottieComponent,
    NgbAccordionModule,
    NgbOffcanvasModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgbModalModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    NgbNavModule,
    NgScrollbarModule,
    AngularSvgIconModule.forRoot(),
    ClipboardModule,
    NgSelectModule,
    NgxGaugeModule,
    FacebookModule.forRoot(),
    EditorModule,
    NgxMaskDirective,
    NgxDatatableModule,
    InfiniteScrollDirective,
    SwiperModule,
    YouTubePlayerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [
    // reexport common
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // export common libraries
    DateFnsModule,
    ToastrModule,
    LottieComponent,
    NgbAccordionModule,
    NgbOffcanvasModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgbModalModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    NgbNavModule,
    NgScrollbarModule,
    AngularSvgIconModule,
    ClipboardModule,
    NgSelectModule,
    NgxGaugeModule,
    FacebookModule,
    EditorModule,
    NgxMaskDirective,
    NgxDatatableModule,
    InfiniteScrollDirective,
    SwiperModule,
    YouTubePlayerModule,
    RecaptchaModule,
    RecaptchaFormsModule,

    // pipes
    ...sharedPipes,

    // directives
    ...sharedDirectives,

    // components
    ...sharedComponents
  ],
  declarations: [
    // pipes
    ...sharedPipes,

    // directives
    ...sharedDirectives,

    // components
    ...sharedComponents
  ],
  providers: [
    { provide: DateFnsConfigurationService, useValue: romanianConfig },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, //Use NgbDateNativeUTCAdapter becuase NgbDateNativeAdapter returns the time part set at noon instead of midnight
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
    { provide: NgbTimeAdapter, useClass: CustomNgbTimeAdapter },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useValue: {
        onBeforeLoad(url) {
          url.searchParams.set('hl', 'ro');
          return { url };
        },
      } as RecaptchaLoaderOptions,
    },
    provideNgxMask(),
    provideLottieOptions({
      player: () => player,
    }),
  ]
})
export class SharedModule {
  constructor(
    private modalConfig: NgbModalConfig,
    private timepickerConfig: NgbTimepickerConfig,
  ) {

    //Global ng-bootstrap config
    this.modalConfig.animation = true;
    this.modalConfig.keyboard = true;
    this.modalConfig.backdrop = true;
    this.modalConfig.centered = true;

    // this.timepickerConfig.meridian = true;
    this.timepickerConfig.readonlyInputs = true;
  }
}
