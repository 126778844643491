import { Injectable } from '@angular/core';
import {
  NgbTimeAdapter,
  NgbTimeStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { padNumber } from '../../core/helpers';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomNgbTimeAdapter extends NgbTimeAdapter<string> {
	fromModel(value: string | null): NgbTimeStruct | null {
		if (!value) {
			return null;
		}
		const split = value.split(':');
		return {
			hour: parseInt(split[0], 10),
			minute: parseInt(split[1], 10),
			second: split.length > 2 ? parseInt(split[2], 10) : null,
		};
	}

	toModel(time: NgbTimeStruct | null): string | null {
		return time != null ? `${padNumber(time.hour)}:${padNumber(time.minute)}${time.second ? ':' + padNumber(time.second) : ''}` : null;
	}
}
