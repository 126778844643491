export interface Address {
  id: number;
  type: AddressType;
  firstName: string;
  lastName: string;
  cityId: number;
  street: string;
  company: string;
  fiscalCode: string;
  registrationNo: string;
  isDefault: boolean;
}

export interface AddressUpsert {
  id?: number;
  type: AddressType;
  firstName: string;
  lastName: string;
  cityId: number;
  street: string;
  company: string;
  fiscalCode: string;
  registrationNumber: string;
  isDefault?: boolean;
}

export interface BillingAddress {
  type: AddressType;
  firstName: string;
  lastName: string;
  cityId: number;
  street: string;
  company: string;
  fiscalCode: string;
  registrationNo: string;
  addressId?: number;
}

//TODO: Return all enums similarly: either int for both GET and POST/PUT or string for both GET and POST/PUT
// Gender is handled as int
export enum AddressType {
  Personal = "Personal",
  Company = "Company",
}

export interface City {
  id: number;
  name: string;
  nameFormatted: string;
  countyId: number;
  countyName: string;
}
