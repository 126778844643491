import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const CC_REGEXP =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
export const NAME_REGEXP = /^([a-zA-ZĂăÂâÎîȘșȚțŞşŢţ]+['.-]?\s?)+$/;
export const EXT_NAME_REGEXP = /^([a-zA-Z0-9ĂăÂâÎîȘșȚțŞşŢţ]+['.-]?\s?)+$/;
export const PHONE_REGEXP = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
export const FISCAL_CODE_REGEXP = /^(RO)?\d{2,10}$/i;
export const REG_NUMBER_REGEXP = /^[JFC]\d{1,2}\/\d{1,4}\/\d{4}$/i;
/*
Alternatives which allow domains without TLD:
https://www.w3.org/TR/2012/WD-html-markup-20121025/input.email.html#form.data.emailaddress_xref2 - the official implementation for input type="email"
/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
https://github.com/angular/angular/blob/9847085448feff29ac6d51493e224250990c3ff0/packages/forms/src/validators.ts#L149
/^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
*/
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//export const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export class GiftShareValidators {

  /**
   * Credit card validator
   * Source: https://github.com/yuyang041060120/ng2-validation/blob/master/src/credit-card/validator.ts
   *
   * @static
   * @returns {ValidatorFn}
   * @memberof AlpValidators
   */
  static creditCardNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (GiftShareValidators.isEmptyInputValue(control.value)) {
        return null;  // don't validate empty values to allow optional controls
      }

      const sanitized = control.value.replace(/[^0-9]+/g, '');

      // problem with chrome
      if (!(CC_REGEXP.test(sanitized))) {
        return { creditCardNumber: true };
      }

      let sum = 0;
      let digit;
      let tmpNum;
      let shouldDouble;

      for (let i = sanitized.length - 1; i >= 0; i--) {
        digit = sanitized.substring(i, (i + 1));
        tmpNum = parseInt(digit, 10);
        if (shouldDouble) {
          tmpNum *= 2;
          if (tmpNum >= 10) {
            sum += ((tmpNum % 10) + 1);
          } else {
            sum += tmpNum;
          }
        } else {
          sum += tmpNum;
        }
        shouldDouble = !shouldDouble;
      }

      if ((sum % 10) === 0 ? sanitized : false) {
        return null;
      }

      return { creditCardNumber: true };

    };
  }

  /**
   * Validator that requires the control's value pass an email validation test.
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors|null)}
   * @memberof GiftShareValidators
   */
  static email(control: AbstractControl): ValidationErrors | null {
    if (GiftShareValidators.isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional controls
    }
    return EMAIL_REGEXP.test(control.value) ? null : { 'email': true };
  }

  /**
   * Validator for date and time.
   *
   * @static
   * @param {string} dateControlName
   * @param {string} timeControlName
   * @returns {ValidatorFn}
   * @memberof GiftShareValidators
   */
  static futureDate(dateControlName: string, timeControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateControl = control.get(dateControlName);
      const timeControl = control.get(timeControlName);

      if (!dateControl?.value || !timeControl?.value) {
        return null;  // don't validate empty values to allow optional controls
      }

      const date = new Date(dateControl.value.getFullYear(), dateControl.value.getMonth(), dateControl.value.getDate(),
      timeControl.value.split(':')[0], timeControl.value.split(':')[1]);

      return date <= new Date() ? {'futureDate': true} : null;
    };
  }

  //Sample validators

  // public static syncValidation(minHours: number): ValidatorFn {
  //   return (form: FormGroup): ValidationErrors | null => {

  //   };
  // }

  // public static asyncValidation(service: Service): AsyncValidatorFn {
  //   return (control: FormControl): Observable<ValidationErrors> => {
  //     return

  //     );
  //   };
  // }

  /**
   * Check if input has value
   * We don't check for string here so it also works with arrays
   *
   * @private
   * @static
   * @param {*} value
   * @returns {boolean}
   * @memberof AlpValidators
   */
  private static isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
  }

  /**
   * Remove a specific error from control
   *
   * @private
   * @static
   * @param {AbstractControl} control
   * @param {string} error
   * @memberof NorthlineValidators
   */
  private static removeError(control: AbstractControl, error: string) {
    const errors = control.errors;
    if (errors && errors[error]) {
      delete errors[error];
      if (!Object.keys(errors).length) {
        control.setErrors(null);
      } else {
        control.setErrors(errors);
      }
    }
  }

  /**
   * Add a new error to existing collection
   *
   * @private
   * @static
   * @param {AbstractControl} control
   * @param {*} error
   * @memberof NorthlineValidators
   */
  private static addError(control: AbstractControl, error: any) {
    control.setErrors({ ...control.errors, ...error });
  }

}
