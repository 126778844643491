
import { Injectable } from '@angular/core';
import { Consent } from '../models';

declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  constructor() {
  }

  /**
   * Track AW conversion
   *
   * @param {string} group
   * @param {*} [callback=null]
   * @memberof GtagService
   */
  trackConversion(group: string, callback?) {
    gtag('event',
      'conversion',
      {
        'send_to': group,
        'event_callback': callback
      }
    );
  }

  /**
   * Generic event
   *
   * @param {string} eventName
   * @param {string} eventContent
   * @memberof GtagService
   */
  trackEvent(eventName: string, eventContent: any) {
    gtag('event',
      eventName,
      eventContent
    );
  }

  /**
   * Track page change
   *
   * @param {string} trackingId
   * @param {string} url
   * @memberof GtagService
   */
  trackPage(trackingId: string, url: string) {
    gtag('config',
      trackingId,
      {
        'page_path': url
      }
    );
  }

  /**
   * Update user consent
   *
   * Since consent mode doesn't save consent choices, update the consent status as soon as a user interacts with your consent management solution.
   * After a user grants consent, persist their choice and call the update command accordingly on subsequent pages.
   *
   * ad_user_data - Sets consent for sending user data to Google for advertising purposes.
   * ad_personalization - Sets consent for personalized advertising.
   * ad_storage	- Enables storage, such as cookies (web) or device identifiers (apps), related to advertising.
   * analytics_storage - Enables storage, such as cookies (web) or device identifiers (apps), related to analytics, for example, visit duration.
   * functionality_storage - Enables storage that supports the functionality of the website or app, for example, language settings
   * personalization_storage - Enables storage related to personalization, for example, video recommendations
   * security_storage- Enables storage related to security such as authentication functionality, fraud prevention, and other user protection
   *
   * Unclear how subsequent updates should be handled, if stored consent should be loaded as default or call update everytime ( https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#update-consent )
   * Quote: After a user grants consent, persist their choice and call the update command accordingly on subsequent pages.
   * vs https://www.youtube.com/watch?v=MqAEbshMv84
   *
   * @param {boolean} allow
   * @memberof GtagService
   * @see https://support.google.com/tagmanager/answer/13695607?hl=en&ref_topic=14226521&sjid=6425376721227243819-EU
   * @see https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced#update-consent
   */
  updateConsent(consent: Consent) {
    console.log('Consent update:', consent);
    gtag('consent', 'update', {
      'ad_storage': consent.isMarketingAllowed ? 'granted' : 'denied',
      'ad_user_data': consent.isMarketingAllowed ? 'granted' : 'denied',
      'ad_personalization': consent.isMarketingAllowed ? 'granted' : 'denied',
      'analytics_storage': consent.isStatisticsAllowed ? 'granted' : 'denied',
      'functionality_storage': consent.isPreferencesAllowed ? 'granted' : 'denied',
      'personalization_storage': consent.isPreferencesAllowed ? 'granted' : 'denied',
      'security_storage': consent.isNecessaryAllowed ? 'granted' : 'denied',
    });
  }
}
