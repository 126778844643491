import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthGuard, RoleGuard, throwIfAlreadyLoaded } from './guards';
import { BaseUrlInterceptor, DateInterceptor, ErrorInterceptor, TokenInterceptor } from './interceptors';

/*
Consider collecting numerous, auxiliary, single-use classes inside a core module to simplify the apparent structure of a feature module.
Consider calling the application-wide core module, CoreModule. Importing CoreModule into the root AppModule reduces its complexity and emphasizes its role as orchestrator of the application as a whole.
Do put a singleton service whose instance will be shared throughout the application in the CoreModule (e.g. ExceptionService and LoggerService).
Do import all modules required by the assets in the CoreModule (e.g. CommonModule and FormsModule).
Do gather application-wide, single use components in the CoreModule. Import it once (in the AppModule) when the app starts and never import it anywhere else. (e.g. NavComponent and SpinnerComponent).
Do export all symbols from the CoreModule that the AppModule will import and make available for other feature modules to use.
*/
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthGuard,
    RoleGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BaseUrlInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
