import {
  trigger, animateChild, group,
  transition, animate, style, query,
  state
} from '@angular/animations';

// Routable animations
export const fadeInOutAnimation =
  trigger('routeAnimations', [
    transition('* => *', [
      query(
        ':enter', [style({ opacity: 0 })],
        { optional: true }
      ),
      query(
        ':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))],
        { optional: true }
      ),
      query(
        ':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))],
        { optional: true }
      )
    ])
  ]);

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ], { optional: true }),
      query(':enter', [
        style({ left: '-100%' })
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%', opacity: 0 }))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%' }))
        ], { optional: true }),
        query('@*', animateChild(), { optional: true })
      ]),
    ])
  ]);

export const panelInOutAnimation =
  trigger('panelInOut', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [
      style({ opacity: 0 }),
      animate(300)
    ]),
    transition(':leave',
      animate(300, style({ opacity: 0 })))
  ]);
