
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { FacebookService } from 'ngx-facebook';
import { tap } from 'rxjs/operators';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

import { environment } from '../../../environments/environment';
import { ExternalProviderRequest, LoginResponse } from '../../core/models';
import { AuthActions } from '../../state/auth.state';
import { isAdministratorUser } from '../helpers/auth-utils';

declare var WL: any;
// declare var FB: any;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private socialAuthService: SocialAuthService,
    private facebookService: FacebookService,
    private store: Store,
    private router: Router
  ) {
    this.initSocialLibraries();
  }

  private async initSocialLibraries() {
    try {
      await this.facebookService.init({
        appId: environment.facebookAppId,
        cookie: false,
        status: false,
        xfbml: true,
        version: environment.facebookApiVersion
      });
    } catch (error) {
      console.error(`Facebook init error.`, error);
    }

    try {
      await WL.init({
        client_id: environment.outlookId,
        redirect_uri: window.location.origin + '/auth/autentificare',
        response_type: 'token',
        scope: ['wl.signin', 'wl.birthday', 'wl.basic', 'wl.emails', 'wl.postal_addresses', 'wl.photos']
      });
    } catch (error) {
      console.error(`Microsoft init error.`, error);
    }
  }

  login(model: any) {
    return this.http.post<LoginResponse>(`${this.endpoint}/login`, model)
      .pipe(
        tap(response => {
          if (response.token && response.user) {

            if (isAdministratorUser(response.user)) {
              throw new Error(`Actiune neautorizata. Permisiuni insuficiente pentru aceasta actiune.`);
            }

            this.store.dispatch([
              new AuthActions.SetTokens({ token: response.token, tokenExpires: response.tokenExpires }),
              new AuthActions.SetUser(response.user),
              new AuthActions.GetCartCount(),
              new AuthActions.GetNotifications(),
              new AuthActions.StartNotifications(),
            ]);
          }
        }),
      );
  }

  logout(redirectToAuth: boolean = false, returnUrl?: string) {
    this.store.dispatch([
      new AuthActions.Logout(),
      new AuthActions.StopNotifications(),
    ]);

    //Redirect only when not on AUTH pages to let pages display authentication errors
    if (this.router.url.toUpperCase().startsWith('/AUTH/') == false) {
      if (redirectToAuth) {
        this.router.navigate(['/auth/autentificare'], { queryParams: { returnUrl: returnUrl || this.router.url } });
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  async googleLogin(): Promise<LoginResponse> {
    const providerName = 'google';
    const result = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);

    return await this.externalProviderLogin({ providerName: providerName, token: result.authToken }).toPromise();
  }

  microsoftLogin(): Promise<LoginResponse> {
    const providerName = 'microsoft';
    const that: any = this;
    return new Promise((resolve, reject) => {
      WL.login()
        .then(response => {
          const result = that.externalProviderLogin({ providerName: providerName, token: response.session.access_token }).toPromise();
          resolve(result);
        });
    });
  }

  facebookLogin(): Promise<LoginResponse> {
    const providerName = 'facebook';
    const that: any = this;
    // return this.facebookService.getLoginStatus()
    //   .then(response => {
    //     if (response.authResponse) {
    //       return that.externalProviderLogin({ providerName: providerName, token: response.authResponse.accessToken }).toPromise();
    //     }

    return that.facebookService.login({ scope: 'email' })
      .then(response => {
        if (!response.authResponse) {
          throw new Error(`Invalid response from Facebok.`);
        }
        return that.externalProviderLogin({ providerName: providerName, token: response.authResponse.accessToken }).toPromise();
      });
    // });
  }

  getFacebookLoginStatus() {
    const providerName = 'facebook';
    const that: any = this;
    return this.facebookService.getLoginStatus()
      .then(response => {
        if (response.authResponse) {
          return that.externalProviderLogin({ providerName: providerName, token: response.authResponse.accessToken }).toPromise();
        }
      });
  }

  private externalProviderLogin(model: ExternalProviderRequest) {
    return this.http.post<LoginResponse>(`${this.endpoint}/login-external`, model)
      .pipe(
        tap(response => {
          if (response.token && response.user) {

            if (isAdministratorUser(response.user)) {
              throw new Error(`Actiune neautorizata. Permisiuni insuficiente pentru aceasta actiune.`);
            }

            this.store.dispatch([
              new AuthActions.SetTokens({ token: response.token, tokenExpires: response.tokenExpires }),
              new AuthActions.SetUser(response.user),
              new AuthActions.GetCartCount(),
              new AuthActions.GetNotifications(),
              new AuthActions.StartNotifications(),
            ]);
          }
        })
      );
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/accounts`;
  }
}
