
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable, Subject, from } from 'rxjs';
import * as signalR from "@microsoft/signalr";

import { environment } from '../../../environments/environment';
import { Notification } from '../../core/models';
import { mapDates } from '../helpers';
import { Store } from '@ngxs/store';
import { AuthState } from '../../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationHub: signalR.HubConnection;
  private notificationReceivedSubject: Subject<Notification>;
  public notificationReceived: Observable<Notification>;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    this.notificationReceivedSubject = new Subject<Notification>();
    this.notificationReceived = this.notificationReceivedSubject.asObservable();
  }

  getNotifications(): Observable<Notification[]> {
    return this.http.get<any>(`${this.endpoint}/latest`);
  }

  viewNotifications(notificationIds: number[]): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/view`, notificationIds);
  }

  readNotifications(notificationIds: number[]): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/read`, notificationIds);
  }

  startConnection(): Observable<Notification> {
    if (this.notificationHub == null ||
      this.notificationHub.state === signalR.HubConnectionState.Disconnected ||
      this.notificationHub.state === signalR.HubConnectionState.Disconnecting) {

      // console.log(`Connecting with token ${this.store.selectSnapshot(AuthState.token)}`);
      this.notificationHub = new signalR.HubConnectionBuilder()
        // .configureLogging(signalR.LogLevel.Information)
        .withUrl(`${this.endpoint}/feed`, { accessTokenFactory: () => this.store.selectSnapshot(AuthState.token) })
        .withAutomaticReconnect()
        .build();

      this.notificationHub.on('notification', (x) => {
        // console.log(`Notification received: ${JSON.stringify(x)}`);
        this.notificationReceivedSubject.next(mapDates(x));
      });

      this.notificationHub.onreconnecting(error => {
        console.log('Reconnecting...', error);
      });

      this.notificationHub.onreconnected(() => {
        console.log('Reconnected to the server');
      });

      this.notificationHub.onclose((e?: Error) => {
        if (e) {
          // An error occurs
          if (e.message?.includes('1006')) {
            // Ignore 1006 socket closed to avoid spamming sentry
            console.error('WebSocket closed with status code: 1006');
          } else {
            console.error('Generic SignalR error: ', e);
            this.notificationReceivedSubject.error(e);
          }
        } else {
          // No more events to be sent.
          this.notificationReceivedSubject.complete();
        }
      });

      this.notificationHub
        .start()
        .then(() => console.log('Connection started'))
        .catch(e => console.log('Error while starting connection: ' + e));
    }

    return this.notificationReceivedSubject.asObservable();
  }

  stopConnection(): Observable<void> {
    if (this.notificationHub) {
      return from(
        this.notificationHub.stop()
          .then(() => console.log('Connection stopped'))
          .catch(e => console.log('Error while stopping connection: ' + e))
      )
    }

    return EMPTY;
  }

  get isConnected(): boolean {
    return this.notificationHub?.state === signalR.HubConnectionState.Connected;
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/notifications`;
  }
}
