import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gs-confirm',
  templateUrl: 'confirm.component.html'
})
export class ConfirmComponent implements OnInit {
  @Input() title: string = 'Confirmare';
  @Input() description: string = 'Sigur doresti sa efectuezi aceasta actiune?';
  @Input() acceptLabel: string = 'Da';
  @Input() rejectLabel: string = 'Nu';
  @Input() acceptClass: string = 'btn-primary';
  @Input() rejectClass: string = 'btn-secondary';

  @Output() done = new EventEmitter<'accept' | 'reject' | 'cancel'>();

  constructor(private bsModalRef: NgbActiveModal) {
  }

  ngOnInit() { }

  onReject() {
    this.done.emit('reject');
    this.close();
  }

  onAccept() {
    this.done.emit('accept');
    this.close();
  }

  onClose() {
    this.done.emit('cancel');
    this.close();
  }

  private close() {
    if (this.bsModalRef) {
      this.bsModalRef.close();
    }
  }
}
