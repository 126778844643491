
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContributionService {

  constructor(private http: HttpClient) {
  }

  createContribution(contribution: any) {
    return this.http.post<any>(`${this.endpoint}`, contribution);
  }

  getContribution(contributionId: string) {
    return this.http.get<any>(`${this.endpoint}/${contributionId}`);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/contributions`;
  }
}
