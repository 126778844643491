<div class="modal-header border-0">
  <h4 class="modal-title">{{title}}</h4>
  <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-0" novalidate autocomplete="off">
  <div class="modal-body">
    <div class="row row-cols-1 g-4">
      <div class="col">
        <label class="form-label" for="amount">Valoare</label>
        <input formControlName="amount" [ngClass]="{'is-invalid': vs.hasErrors(form, 'amount')}" inputmode="decimal"
          step="0.01" gsDecimalInput [decimal]="true" [min]="1" [max]="99999" class="form-control form-control-lg"
          type="number" id="amount" autofocus>
        <div *ngIf="vs.hasErrors(form, 'amount')" class="invalid-feedback">{{vs.getError(form,
          'amount')}}
        </div>
      </div>
      <div class="col">
        <label class="form-label" for="address">Date de facturare</label>

        <ng-container *ngIf="address">
          <div class="card rounded-3 p-3 p-sm-4" [ngClass]="{'border-danger': vs.hasErrors(form, 'address')}">
            <div class="d-flex align-items-center pb-2 mb-1">
              <h3 class="h6 text-nowrap text-truncate mb-0">{{(isCompanyAddress ? address.company :
                address.firstName + ' ' + address.lastName) }}
              </h3>
              <span class="badge bg-opacity-10 fs-xs ms-3"
                [ngClass]="{'bg-primary text-primary': !isCompanyAddress, 'bg-success text-success': isCompanyAddress}">Persoana
                {{ isCompanyAddress ? 'juridica' : 'fizica'}}</span>
              <div class="d-flex ms-auto">
                <button (click)="onEditAddress(address)" class="nav-link fs-xl fw-normal py-1 pe-0 ps-1 ms-2"
                  type="button" data-bs-toggle="tooltip" aria-label="Edit">
                  <i class="ai-edit-alt"></i>
                </button>
              </div>
            </div>
            <p class="mb-0">
              <ng-container *ngIf="address.street">{{address.street}}<br></ng-container>
              {{getCity(address?.cityId)?.name || 'n/a'}},
              {{getCity(address?.cityId)?.countyName || 'n/a'}}
            </p>
          </div>
          <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'address')">{{vs.getError(form,
            'address')}}</div>
        </ng-container>

        <!-- No address -->
        <ng-container *ngIf="!address">
          <div class="card h-100 justify-content-center align-items-center border-dashed rounded-3 py-5 px-3 px-sm-4"
            [ngClass]="{'border-danger': vs.hasErrors(form, 'address')}">
            <a (click)="onEditAddress()"
              class="stretched-link d-flex align-items-center fw-semibold text-decoration-none my-sm-3" role="button">
              <i class="ai-circle-plus fs-xl me-2"></i>
              Adauga date de facturare
            </a>
          </div>
          <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'address')">{{vs.getError(form,
            'address')}}</div>
        </ng-container>

      </div>
      <div class="col">
        <label class="form-label" for="captcha">Verificare de securitate</label>
        <re-captcha formControlName="captcha" [siteKey]="recaptchaSiteKey" [theme]="theme$ | async" size="normal"
          (resolved)="onCaptchaResolved($event)" (errored)="onCaptchaError($event)"></re-captcha>
        <div *ngIf="vs.hasErrors(form, 'captcha')" class="invalid-feedback">{{vs.getError(form,
          'captcha')}}
        </div>
      </div>
      <div class="col">
        <div class="form-check">
          <input formControlName="hasAcceptedDepositTerms"
            [ngClass]="{'is-invalid': vs.hasErrors(form, 'hasAcceptedDepositTerms')}" class="form-check-input"
            type="checkbox" id="hasAcceptedDepositTerms">
          <label class="form-check-label text-dark fw-medium" for="hasAcceptedDepositTerms">Am citit si sunt de acord cu
            <a target="_blank" [routerLink]="['/termeni-si-conditii']">
              Termenii si conditiile
            </a>
          </label>
          <div *ngIf="vs.hasError(form, 'hasAcceptedDepositTerms', 'required')" class="invalid-feedback">Termenii si
            conditiile trebuie citite si acceptate.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button (click)="close()" class="btn btn-secondary w-100 w-sm-auto mb-3 mb-sm-0" type="button"
      data-bs-dismiss="modal">Anuleaza</button>
    <button class="btn btn-primary w-100 w-sm-auto ms-sm-3" type="submit">Adauga fonduri</button>
  </div>
</form>
