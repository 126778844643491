import { DOCUMENT } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'gs-scroll-button',
  templateUrl: 'scroll-button.component.html'
})
export class ScrollButtonComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild('svgCircle') svgCircleElementRef: ElementRef;

  buttonClass: string;
  svgCircleStyle: any;
  private scrollOffset: number = 450;
  private circleLength: number;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    if (this.svgCircleStyle) {
      this.buttonClass = e.currentTarget.pageYOffset > this.scrollOffset ? 'show' : '';

      const scrollPercent = (this.document.body.scrollTop + this.document.documentElement.scrollTop) / (this.document.documentElement.scrollHeight - this.document.documentElement.clientHeight);
      this.svgCircleStyle.strokeDashoffset = this.circleLength - (this.circleLength * scrollPercent);
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.circleLength = this.svgCircleElementRef.nativeElement.getTotalLength();

    setTimeout(() => {
      this.svgCircleStyle = {
        'strokeDasharray': this.circleLength,
        'strokeDashoffset': this.circleLength
      };
    });
  }

  ngAfterContentInit(): void {

  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
