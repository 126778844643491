
export class SystemStatistics {
  partnerCount: number;
  voucherCount: number;
  partnerNonCharityCount: number;
  voucherNonCharityCount: number;
  eventCount: number;
  contributionCount: number;
  userCount: number;
  orderCount: number;
}
