import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gs-home-button',
  templateUrl: 'home-button.component.html'
})
export class HomeButtonComponent implements OnInit {
  constructor() {
  }

  ngOnInit() { }

}
