import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import * as Sentry from '@sentry/angular';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor(
    private store: Store
  ) {

    // Set current user
    this.store.select<any>(state => state.auth)
      .pipe(
        filter(x => !!x?.user)
      )
      .subscribe(auth => {
        Sentry.setUser({
          id: auth.user.id.toString(),
          email: auth.user.email
        });
      })
  }

  static init() {
    // https://docs.sentry.io/platforms/javascript/#globalhandlers
    // Sentry.Integrations.GlobalHandlers does NOT work to disable global handlers
    // defaultIntegrations: false does NOT work to disable global handlers
    Sentry.init({
      dsn: environment.sentryConfig.dsn,
      environment: environment.environment,
      // enabled: true,
      // debug: false,
      // allowUrls: [
      //   /https?:\/\/((cdn|www)\.)?giftshare\.ro/i,
      //   /https?:\/\/((cdn|www)\.)?giftfest\.ro/i
      // ],
      denyUrls: [
        // Tawk.to
        /\.tawk\.to/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /\.doubleclick\.net/i,
        /\.google-analytics\.com/i,
        // // Facebook flakiness
        // /graph\.facebook\.com/i,
        // // Facebook blocked
        // /connect\.facebook\.net\/en_US\/all\.js/i,
      ],
      // To configure the integration, use ignoreErrors, denyUrls, and allowUrls SDK options directly.
      // All three options accept an array of strings or RegExp patterns. When provided with a string,
      // they’ll partially match the URL in case of denyUrls and allowUrls, or two variants in case of
      // ignoreErrors - message itself, and ${type}: {message} format. When given RegExp, it will use the
      // RegExp.test method instead, which you can use to achieve an exact match if desired.
      ignoreErrors: [
        // 'idpiframe_initialization_failed',
        // 'Cookies are not enabled in current environment'
        //   /401 Unauthorized|403 Forbidden/,
        //  'Non-Error exception captured',
        // /401 OK|403 OK/ - Does NOT work
      ],
      async beforeSend(event, hint) {
        // For nester errors that are not caught with ignoreErrors above
        const nestedIgnoreErrors = [
          /idpiframe_initialization_failed/i,
          /popup_closed_by_user/i,
          /401 OK/i,
          /403 OK/i,
          /401 Unauthorized/i
        ];

        if (hint.originalException) {
          if (typeof hint.originalException === 'string' &&
            nestedIgnoreErrors.some(x => x.test(hint.originalException as string))) {
            return null;
          }

          const anyException = hint.originalException as any;
          if (typeof anyException.error === 'string' &&
            nestedIgnoreErrors.some(x => x.test(anyException.error))) {
            return null;
          }

          if (typeof anyException.details === 'string' &&
            nestedIgnoreErrors.some(x => x.test(anyException.details))) {
            return null;
          }
        }
        // In JavaScript a function can be used to modify the event or return a completely new one.
        // If you return null, the event will be discarded.

        // Filter dummy exception 'Non-Error exception captured'
        // const isNonErrorException =
        //   event.exception.values[0].value.startsWith('Non-Error exception captured') ||
        //   hint.originalException['message'].startsWith('Non-Error exception captured');

        // if (isNonErrorException) {
        //   // We want to ignore those kind of errors
        //   return null;
        // }

        // Skip 401/403 status
        // We can catch the error in error.interceptor.ts to avoid this but added here for central point of filtering
        const isUnauthenticated =
          hint.originalException instanceof HttpErrorResponse && [401, 403].includes(hint.originalException.status);

        if (isUnauthenticated) {
          return null;
        }

        return event;
      },
      // defaultIntegrations: false,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        //Sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        //Sentry.replayIntegration(),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // replaysSessionSampleRate: 0.1,
      // replaysOnErrorSampleRate: 1.0,
    });
  }

  handleError(error: any) {
    // Ignore crawlers
    // Already ignored by Sentry: https://blog.sentry.io/2017/11/27/setting-up-inbound-filters#filter-out-known-web-crawlers
    // if (/google|baidu|bing|duckduckbot|teoma|slurp|yandex|crawler|spider|robot|crawling/i.test(window.navigator.userAgent)) {
    //   return;
    // }

    // Ignore token expired errors
    // Moved to beforeSend, because these exceptions are handled by global handles as well, even if ignored here;
    // if (error.status == 401) {
    //   return;
    // }

    if (environment.sentryConfig.dsn) {
      const extractedError = this.extractError(error) || 'Handled unknown error';

      // Capture handled exception and send it to Sentry.
      const eventId = Sentry.captureException(extractedError);

      // When in development mode, log the error to console for immediate feedback.
      // if (!environment.production) {
      //   console.error(extractedError);
      // }

      // Optionally show user dialog to provide details on what happened.
      // Sentry.showReportDialog({ eventId });
    }

    console.error(error);
  }

  private extractError(errorCandidate) {
    let error = errorCandidate;

    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }
}
