
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import AOS from 'aos';

import { StaticDataActions, AuthActions, LocalActions } from './state';
import { ApplicationRole, Consent, UserProfile } from './core/models';
import { ProfileModalComponent } from './shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gs-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  readonly ApplicationRole = ApplicationRole;

  user: UserProfile;
  consent: Consent;
  canDisplayCookieBanner: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private titleService: Title,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {

    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
  }

  ngOnInit() {
    AOS.init();

    //Init store
    this.store.dispatch([
      new StaticDataActions.GetSystemStatistics(),
      new StaticDataActions.GetPublicSettings(),
      new StaticDataActions.GetVouchers(),
      new StaticDataActions.GetEventTypes(),
      new StaticDataActions.GetCities()
    ]);

    this.subscriptions.push(
      this.store.select<UserProfile>(state => state.auth.user)
        .subscribe(user => {
          const hasUserLoggedIn = !this.user && user;
          this.user = user;

          if (hasUserLoggedIn) {
            // this.store.dispatch([
            //   new AuthActions.GetCartCount(),
            //   new AuthActions.GetNotifications(),
            //   new AuthActions.StartNotifications(),
            // ]);

            if (
              !user.firstName ||
              !user.lastName ||
              !user.cityId
            ) {
              this.modalService.open(ProfileModalComponent, { backdrop: 'static', keyboard: false });
            }
          }

        }),

      this.store.select<boolean>(state => state.local.canDisplayCookieBanner)
        .subscribe(canDisplayCookieBanner => {
          this.canDisplayCookieBanner = canDisplayCookieBanner;
        }),

      this.store.select<Consent>(state => state.local.consent)
        .subscribe(consent => {
          if (consent) {
            this.store.dispatch(new LocalActions.UpdateGoogleConsent(consent));
          }

          this.store.dispatch(new LocalActions.SetCanDisplayCookieBanner(!consent));
        })
    );

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      // filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        this.titleService.setTitle(event['title']);
      });

    // Refresh user data only on app reload; needed for backend triggered wallet updates
    // Moved to authState.ngxsOnInit
    // if (this.user) {
    //   this.store.dispatch([
    //     new AuthActions.GetCurrentUser(),
    //     new AuthActions.GetCartCount(),
    //     new AuthActions.GetNotifications(),
    //     new AuthActions.StartNotifications(),
    //   ]);
    // }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
