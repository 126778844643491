<main class="page-wrapper bg-secondary no-collapse" [@routeAnimations]="o.activatedRouteData">

  <gs-header></gs-header>

  <!-- Page content-->
  <router-outlet #o="outlet"></router-outlet>

</main>

<gs-footer containerClass="bg-secondary"></gs-footer>

<gs-scroll-button></gs-scroll-button>
