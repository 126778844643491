import { Component, OnInit } from '@angular/core';
import { fadeInOutAnimation } from '../../../core/animations';

@Component({
  selector: 'gs-secondary-layout',
  templateUrl: 'secondary-layout.component.html',
  animations: [fadeInOutAnimation]
})
export class SecondaryLayoutComponent implements OnInit {
  constructor() {
  }

  ngOnInit() { }
}
