
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {

  constructor(private http: HttpClient) {
  }

  createSubscription(model: any) {
    return this.http.post<any>(`${this.endpoint}/subscribe`,
      model);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/newsletter`;
  }
}
