import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { roundNumber } from '../../../core/helpers';
import { AddressType, BillingAddress, City, Deposit } from '../../../core/models';
import { DepositService, OrderService, ValidationService } from '../../../core/services';
import { ToastService } from '../../../core/services/toast.service';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { BillingAddressModalComponent } from '../billing-address-modal/billing-address-modal.component';

@Component({
  selector: 'gs-deposit-modal',
  templateUrl: 'deposit-modal.component.html',
})
export class DepositModalComponent implements OnInit, OnDestroy {
  @Select(state => state.local.theme) theme$: Observable<ReCaptchaV2.Theme>;

  cities: City[];

  title: string;
  message: string;
  form: FormGroup;
  walletAmount: number;
  // theme: ReCaptchaV2.Theme = 'light';

  private subscriptions: Subscription[] = [];

  get controls() {
    return this.form.controls;
  }

  get recaptchaSiteKey() {
    return environment.googleRecaptcha.siteKey;
  }

  get address(): BillingAddress {
    return this.form.value.address;
  }

  get isCompanyAddress(): boolean {
    return this.form.controls.address.value
      && this.form.controls.address.value.type === AddressType.Company;
  }

  constructor(
    private bsModalRef: NgbActiveModal,
    private toastService: ToastService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private depositService: DepositService,
    private orderService: OrderService,
    private modalService: NgbModal,
    private store: Store,
    private formBuilder: FormBuilder,
    public vs: ValidationService
  ) {

    this.form = this.formBuilder.group({
      amount: [null, [Validators.required]],
      hasAcceptedDepositTerms: [false, [Validators.requiredTrue]],
      address: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([
        this.store.select(state => state.auth.user),
        this.orderService.getCartItems()
      ]).subscribe(([user, cartItems]) => {
        if (!this.address) {
          const defaultAddress = user?.addresses.find(x => x.isDefault);
          if (defaultAddress) {
            const { id, ...addressRest } = defaultAddress;
            this.form.patchValue({
              address: { ...addressRest, addressId: defaultAddress.id }
            });
          }
        }

        if (user?.walletAmount != null && cartItems?.length > 0) {
          const walletAmount = user.walletAmount;
          const cartTotal = cartItems.reduce((sum, current) => sum + current.total, 0);

          if (cartTotal > walletAmount) {
            this.form.patchValue({
              amount: roundNumber(cartTotal - walletAmount)
            });
          }
        }
      }),

      this.store.select<City[]>(state => state.staticData.cities)
        .pipe(filter(x => !!x))
        .subscribe(cities => {
          this.cities = cities;
        })
    );

    // this.subscriptions.push(
    //   this.store.select<string>(state => state.local.theme)
    //     .subscribe(theme => {
    //       this.theme = theme as ReCaptchaV2.Theme;
    //     }),
    // );

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.bsModalRef.close();
  }

  // This will be invoked only when setting errorMode to handled
  // https://github.com/DethAriel/ng-recaptcha?tab=readme-ov-file#handling-errors
  onCaptchaResolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onCaptchaError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered:`, errorDetails);
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    const deposit = {
      ...this.form.value
    } as Deposit;

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.depositService.createDeposit(deposit)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe((response: any) => {
          this.processPayment(response.form);
          this.close();
        })
    );
  }

  getCity(cityId: number): City {
    return this.cities?.find(x => x.id === cityId);
  }

  onEditAddress(address: BillingAddress = null) {
    address = address ?? { type: AddressType.Personal } as BillingAddress;

    const modalRef = this.modalService.open(BillingAddressModalComponent, { backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.address = address;

    this.subscriptions.push(
      modalRef.closed
        .subscribe((result: BillingAddress) => {
          if (result) {
            this.form.patchValue({
              address: { ...result }
            })
          }
        })
    );
  }

  /**
   * Redirects to Payment Gateway Process.
   * @param {*} data
   * @memberof DepositModalComponent
   */
  private processPayment(data: any) {
    data = data.replace(/\\/g, '');
    const form = document.createElement('form');
    form.id = 'PostForm';
    form.setAttribute('method', 'post');
    form.setAttribute('action', environment.paymentUrl);
    form.innerHTML = data;
    document.body.appendChild(form);
    form.submit();
  }
}
