<!-- Page wrapper-->
<main class="page-wrapper vh-100" [@routeAnimations]="o.activatedRouteData">
  <!-- <gs-header></gs-header> -->

  <!-- Page content-->
  <router-outlet #o="outlet"></router-outlet>

</main>

<gs-scroll-button></gs-scroll-button>
