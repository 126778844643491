import { UserProfile } from ".";

export interface LoginResponse extends UserTokens {
  user: UserProfile;
}

export interface UserTokens {
  token: string;
  tokenExpires: Date;
}
