import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, throwError } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';

import { AccountService, ValidationService } from '../../../core/services';
import { ToastService } from '../../../core/services/toast.service';
import { NAME_REGEXP } from '../../validators';
import { City, UserProfile, UserProfileUpdate } from '../../../core/models';
import { AuthActions } from '../../../state';
import { formatDate } from '@angular/common';

@Component({
  selector: 'gs-profile-modal',
  templateUrl: 'profile-modal.component.html',
})
export class ProfileModalComponent implements OnInit, OnDestroy {

  title = "Actualizeaza profilul";
  cities: City[] = [];
  user: UserProfile;

  form: FormGroup = this.formBuilder.group({
    firstName: [null, [Validators.required, Validators.maxLength(50),
    Validators.pattern(NAME_REGEXP)]],
    lastName: [null, [Validators.required, Validators.maxLength(50),
    Validators.pattern(NAME_REGEXP)]],
    //email: [null, [Validators.required, Validators.maxLength(256), GiftShareValidators.email]],
    cityId: [null, [Validators.required, Validators.maxLength(50)]],
    birthDate: [null],
    hasNewsletter: [false],
    phone: [null],
    gender: [null],
    hobbyIds: [[]],
    image: [],
  });

  private subscriptions: Subscription[] = [];

  get controls() {
    return this.form.controls;
  }

  get isFirstNameMissing(): boolean {
    return !this.user?.firstName;
  }

  get isLastNameMissing(): boolean {
    return !this.user?.lastName;
  }

  // get isEmailMissing(): boolean {
  //   return !this.user?.email;
  // }

  get isCityMissing(): boolean {
    return !this.user?.cityId;
  }

  constructor(
    private bsModalRef: NgbActiveModal,
    private toastService: ToastService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private accountService: AccountService,
    private store: Store,
    private formBuilder: FormBuilder,
    public vs: ValidationService
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select<UserProfile>(state => state.auth.user)
        //.pipe(filter(x => !!x))
        .subscribe(user => {
          this.user = user;

          //If there is no user it means the session expired in the meantime and the user was logged out
          //As a consequence we need to close the modal and retry after the user logs in again
          if (!this.user) {
            this.close();
            return;
          }

          this.form.patchValue({
            ...user,
            hobbyIds: this.user.hobbies?.map(x => x.id)
          })
        }),

      this.store.select<City[]>(state => state.staticData.cities)
        .pipe(filter(x => !!x))
        .subscribe((data: City[]) => {
          this.cities = data;
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.bsModalRef.close();
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    const model = {
      ...this.form.value,
      birthDate: this.user.birthDate != null ? formatDate(this.user.birthDate, 'yyyy-MM-dd', 'en') : null,
    } as UserProfileUpdate;

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.accountService.updateUser(this.user.code, model)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe(() => {
          this.toastService.show('Succes', 'Modificarile au fost salvate cu succes.', 'success');

          // this.store.dispatch(new AuthActions.SetUser({
          //   ...this.user,
          //   ...this.form.value,
          //   name: this.form.value.firstName + ' ' + this.form.value.lastName,
          // }));

          this.store.dispatch(new AuthActions.GetCurrentUser());
          this.form.reset();
          this.close();
        })
    );

  }
}
