
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  constructor(private http: HttpClient) {
  }

  getPublicSettings() {
    return this.http.get<any>(`${this.endpoint}/public`);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/settings`;
  }
}
