<ngx-ui-loader fgsColor="#00A4EF" fgsType="rectangle-bounce-pulse-out-rapid" [fgsSize]="60" min="300" max="60000"
  [hasProgressBar]="false">
</ngx-ui-loader>
<ngx-ui-loader [loaderId]="'http-loader'" bgsColor="#00A4EF" bgsType="rectangle-bounce-pulse-out-rapid"
  bgsPosition="bottom-center" [bgsSize]="50" fgsType="rectangle-bounce-pulse-out-rapid" pbColor="#FFFFFF"
  [pbThickness]="5" [hasProgressBar]="true" min="300" max="60000"></ngx-ui-loader>
<ngx-ui-loader [loaderId]="'router-loader'" bgsColor="#00A4EF" bgsType="rectangle-bounce-pulse-out-rapid"
  bgsPosition="bottom-center" [bgsSize]="50" fgsType="rectangle-bounce-pulse-out-rapid" pbColor="#00A4EF"
  [pbThickness]="5" [hasProgressBar]="true" min="300" max="60000"></ngx-ui-loader>

<router-outlet></router-outlet>

<gs-cookie-banner *ngIf="canDisplayCookieBanner"></gs-cookie-banner>
