import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, throwError } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';

import { AccountService, ValidationService } from '../../../core/services';
import { ToastService } from '../../../core/services/toast.service';
import { FISCAL_CODE_REGEXP, NAME_REGEXP, REG_NUMBER_REGEXP } from '../../validators';
import { Address, AddressType, AddressUpsert, BillingAddress, City, UserProfile } from '../../../core/models';
import { AuthActions } from '../../../state';

@Component({
  selector: 'gs-billing-address-modal',
  templateUrl: 'billing-address-modal.component.html',
})
export class BillingAddressModalComponent implements OnInit, OnDestroy {

  address: BillingAddress;
  user: UserProfile;

  title: string = 'Adauga date de facturare';
  cities: City[] = [];

  form: FormGroup = this.formBuilder.group({
    //addressId: [null],
    type: ['Personal'],
    firstName: [null, [Validators.required, Validators.maxLength(50),
    Validators.pattern(NAME_REGEXP)]],
    lastName: [null, [Validators.required, Validators.maxLength(50),
    Validators.pattern(NAME_REGEXP)]],
    cityId: [null, [Validators.required]],
    street: [{ value: null, disabled: true }, [Validators.maxLength(100)]],
    fiscalCode: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(12), Validators.pattern(FISCAL_CODE_REGEXP)]],
    registrationNumber: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(15), Validators.pattern(REG_NUMBER_REGEXP)]],
    company: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(100)]],
    canSaveAddress: [false, [Validators.required]],
  });

  private subscriptions: Subscription[] = [];

  get controls() {
    return this.form.controls;
  }

  get isCompanyAddress(): boolean {
    return this.form.controls.type.value === AddressType.Company;
  }

  get canShowExistingAddresses(): boolean {
    return this.user?.addresses?.length > 0;
  }

  get personalAddress(): Address {
    return this.user?.addresses.find(x => x.type === AddressType.Personal);
  }

  get companyAddress(): Address {
    return this.user?.addresses.find(x => x.type === AddressType.Company);
  }

  constructor(
    private bsModalRef: NgbActiveModal,
    private toastService: ToastService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private accountService: AccountService,
    private store: Store,
    private formBuilder: FormBuilder,
    public vs: ValidationService
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select<UserProfile>(state => state.auth.user)
        .pipe(filter(x => !!x))
        .subscribe(user => {
          this.user = user;
        }),

      this.store.select<City[]>(state => state.staticData.cities)
        .pipe(filter(x => !!x))
        .subscribe((data: City[]) => {
          this.cities = data;
        }),

      this.controls.type.valueChanges
        .subscribe(x => {

          if (x === 'Company') {
            this.controls.fiscalCode.enable();
            this.controls.registrationNumber.enable();
            this.controls.company.enable();
            this.controls.street.enable();

            this.controls.firstName.disable();
            this.controls.lastName.disable();
          } else {
            this.controls.fiscalCode.disable();
            this.controls.registrationNumber.disable();
            this.controls.company.disable();
            this.controls.street.disable();

            this.controls.firstName.enable();
            this.controls.lastName.enable();
          }

          this.form.updateValueAndValidity();
        }),

    );

    this.form.patchValue({
      ...this.address,
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Dismisses the modal with an optional `reason` value: ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK, and custom reasons. Used for negative actions such as clicking the "Cancel" button.
   *
   * @param {string} [reason=null]
   * @memberof AddressModalComponent
   */
  dismiss(reason: string = null) {
    this.bsModalRef.dismiss(reason);
  }

  /**
   * Closes the modal with an optional `result` value. Used for positive actions such as clicking a "Save" button.
   *
   * @param {string} [result=null]
   * @memberof AddressModalComponent
   */
  close(address: BillingAddress) {
    this.bsModalRef.close(address);
  }

  onFiscalCodeSearch() {
    if (!this.controls.fiscalCode.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    this.getCompanyDetails(this.controls.fiscalCode.value);
  }

  private getCompanyDetails(fiscalCode: string) {
    if (!fiscalCode) {
      return;
    }

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.accountService.getCompanyDetails(fiscalCode)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe(data => {
          if (data) {
            this.form.patchValue({
              ...data
            })
          }
        })
    );
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    const model = {
      // ...this.address,
      ...this.form.value
    };

    // Action to run after saving address if needed
    const callback = () => {
      this.close(model as BillingAddress);
    };

    if (this.user && this.form.value.canSaveAddress) {
      this.saveProfileAddress(model as AddressUpsert, callback);
    } else {
      callback();
    }
  }

  saveProfileAddress(model: AddressUpsert, callback) {
    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.accountService.upsertAddress(this.user.code, model)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(() => e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe((address: Address) => {
          this.toastService.show('Succes', 'Modificarile au fost salvate cu succes.', 'success');
          this.store.dispatch(new AuthActions.SetUserAddress(address));

          if (callback) {
            callback();
          }
        })
    );
  }

  getCity(cityId: number): City {
    return this.cities?.find(x => x.id === cityId);
  }

  onUseAddressClick(address: Address) {
    this.close({ ...address, addressId: address.id } as BillingAddress);
  }
}
