<header class="navbar navbar-expand-lg fixed-top bg-light shadow-sm">
  <div class="container">
    <a class="navbar-brand pe-sm-3 pt-0" [routerLink]="['/']">
      <span class="text-primary flex-shrink-0 me-2">
        <span class="d-none d-sm-inline-block">
          <svg-icon src="assets/images/icons/giftshare-logo.svg" svgClass="d-dark-mode-none"
            [svgStyle]="{'height.px': 32}"></svg-icon>
          <svg-icon src="assets/images/icons/giftshare-logo-light.svg" svgClass="d-none d-dark-mode-inline-block"
            [svgStyle]="{'height.px': 32}"></svg-icon>
        </span>
        <span class="d-sm-none">
          <svg-icon src="assets/images/icons/giftshare-logo-alt.svg" svgClass="d-dark-mode-none"
            [svgStyle]="{'height.px': 32}"></svg-icon>
          <svg-icon src="assets/images/icons/giftshare-logo-alt-light.svg" svgClass="d-none d-dark-mode-inline-block"
            [svgStyle]="{'height.px': 32}"></svg-icon>
        </span>
      </span>
    </a>
    <div *ngIf="user" class="nav flex-nowrap align-items-center order-lg-2 ms-auto">
      <a [routerLink]="[( isCorporateUser(user) ? '/corporate/tranzactii' : '/user/tranzactii')]" class="me-2 me-lg-3">
        <span class="badge bg-faded-info text-info fs-sm">{{user?.walletAmount}} lei</span>
      </a>
      <a [routerLink]="['/user/cos']" *ngIf="isStandardUser(user)"
        class="nav-link position-relative me-1 me-lg-1 fs-4 p-2"><i class="ai-cart"></i>
        <ng-container *ngIf="cartCount$ | async as cartCount">
          <span *ngIf="cartCount > 0" class="badge bg-primary fs-xs position-absolute end-0 top-0 me-n1"
            style="padding: .25rem .375rem;">{{cartCount}}</span>
        </ng-container>
      </a>

      <!-- Notifications -->
      <div ngbDropdown (openChange)="onNotificationOpenChange($event)">
        <a ngbDropdownToggle class="nav-link position-relative me-3 me-lg-4 fs-4 p-2 remove-caret" role="button"
          aria-expanded="false"><i class="ai-bell"></i>
          <span *ngIf="unreadNotificationCount > 0" class="badge bg-primary fs-xs position-absolute end-0 top-0 me-n1"
            style="padding: .25rem .375rem;">{{unreadNotificationCount}}</span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end dropdown-menu-no-hover my-1 w-80 w-sm-96 ">
          <h6 class="dropdown-header fs-xs fw-medium text-muted text-uppercase">Notificari
            <span *ngIf="unreadNotificationCount > 0" class="badge bg-faded-danger text-danger ms-1">{{
              (unreadNotificationCount === 1 ?
              'una noua' : unreadNotificationCount + ' noi')}}</span>
          </h6>

          <ng-scrollbar class="h-80 overflow-y-auto">
            <a *ngFor="let notification of notifications" ngbDropdownItem
              (click)="navigateToNotificationUrl(notification)" class="dropdown-item text-wrap" role="button">
              <div class="d-flex align-items-center py-1 w-100">
                <img *ngIf="notification.user" class="rounded-circle object-fit-cover flex-shrink-0 w-10 h-10"
                  [src]="getNotificationUserImageUrl(notification.user)" [alt]="notification.user.name">
                <div *ngIf="!notification.user" [ngClass]="getNotificationIconBackground(notification)"
                  class="rounded-circle d-flex justify-content-center flex-shrink-0 align-items-center w-10 h-10 p-2">
                  <i [ngClass]="getNotificationIcon(notification)" class="fs-4 fw-semibold opacity-70"></i>
                </div>
                <div class="ps-3">
                  <p class="text-dark mb-0" [innerHTML]="notification.content | safe: 'html'"></p>
                  <p class="fs-sm text-muted mb-0">acum {{notification.createdDate | dfnsFormatDistanceToNowStrictPure}}
                  </p>
                </div>
              </div>
            </a>

            <div *ngIf="notifications?.length === 0" class="card border-0 bg-secondary text-center m-3">
              <svg-icon src="assets/images/illustrations/no-data.svg" svgClass="w-50 h-50 mt-3"></svg-icon>
              <div class="card-body">
                <h4 class="card-title">Nicio notificare</h4>
              </div>
            </div>
          </ng-scrollbar>
        </div>
      </div>

    </div>


    <!-- Account on screens > 576px-->
    <div *ngIf="user" ngbDropdown class="dropdown nav d-none d-sm-block order-lg-3">
      <a ngbDropdownToggle class="nav-link d-flex align-items-center p-0" role="button" aria-expanded="false"><img
          class="border rounded-circle object-fit-cover" [src]="userImageUrl" style="width:48px;height:48px;"
          alt="{{user?.name}}">
        <div class="ps-2">
          <div class="fs-xs lh-1 opacity-60">Salut,</div>
          <div class="fs-sm">{{user?.firstName}}</div>
        </div>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end my-1">
        <ng-container
          *ngTemplateOutlet="isCorporateUser(user) ? corporateMenuTemplate : userMenuTemplate"></ng-container>
      </div>
    </div>
    <a *ngIf="!user" class="btn btn-primary btn-sm rounded-pill fs-sm order-lg-3 d-none d-sm-inline-flex ms-auto"
      [routerLink]="['/auth/autentificare']"><i class="ai-login fs-xl me-2 ms-n1"></i>Autentificare
    </a>
    <button (click)="collapsed = !collapsed" [class.collapsed]="collapsed" [attr.aria-expanded]="!collapsed"
      class="navbar-toggler ms-sm-3" type="button"><span class="navbar-toggler-icon"></span>
    </button>

    <nav class="navbar-collapse collapse" [(ngbCollapse)]="collapsed">
      <ul class="navbar-nav navbar-nav-scroll me-auto" style="--ar-scroll-height: 580px;">

        <!-- Account on screens < 576px-->
        <li *ngIf="user" ngbDropdown class="nav-item dropdown d-sm-none show">
          <a ngbDropdownToggle class="nav-link" role="button" aria-expanded="true"><img
              class="border rounded-circle object-fit-cover" [src]="userImageUrl" style="width:48px;height:48px;"
              alt="{{user?.name}}">
            <div class="ps-2">
              <div class="fs-xs lh-1 opacity-60">Salut,</div>
              <div class="fs-sm">{{user?.firstName}}</div>
            </div>
          </a>
          <div ngbDropdownMenu class="dropdown-menu show">
            <ng-container
              *ngTemplateOutlet="isCorporateUser(user) ? corporateMenuTemplate : userMenuTemplate"></ng-container>
          </div>

        </li>

        <ng-container
          *ngTemplateOutlet="isCorporateUser(user) ? corporateHeaderTemplate : userHeaderTemplate"></ng-container>

      </ul>
      <div *ngIf="!user" class="d-sm-none p-3 mt-n3">
        <a class="btn btn-primary w-100 mb-1" [routerLink]="['/auth/autentificare']"><i
            class="ai-login fs-xl me-2 ms-n1"></i>Autentificare</a>
      </div>
    </nav>

  </div>
</header>

<!-- TODO: These can be injected as ng-content from layouts -->
<ng-template #userHeaderTemplate>
  <li class="nav-item"><a (click)="collapsed = true" [routerLink]="['/cadoul-perfect']" class="nav-link">Carduri
      cadou</a></li>
  <li class="nav-item"><a (click)="collapsed = true" [routerLink]="['/cum-functioneaza']" class="nav-link">Cum
      functioneaza</a></li>
  <li class="nav-item"><a (click)="collapsed = true" [routerLink]="['/card-cadou-corporate']"
      class="nav-link">Corporate</a></li>
  <li class="nav-item"><a (click)="collapsed = true" [routerLink]="['/evenimente-publice']"
      class="nav-link">Evenimente</a></li>
</ng-template>

<ng-template #corporateHeaderTemplate>
  <li class="nav-item"><a (click)="collapsed = true" [routerLink]="['/card-cadou-corporate']"
      class="nav-link">Corporate</a></li>
  <li class="nav-item"><a (click)="collapsed = true" [routerLink]="['/parteneri']" class="nav-link">Parteneri</a></li>
  <li class="nav-item"><a (click)="collapsed = true" [routerLink]="['/intrebari-frecvente']" class="nav-link">Intrebari
      frecvente</a></li>
</ng-template>


<ng-template #userMenuTemplate>
  <h6 class="dropdown-header fs-xs fw-medium text-muted text-uppercase pb-1">Dashboard</h6>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/user/dashboard']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-grid fs-lg opacity-70 me-2"></i>Dashboard</a>
  <a ngbDropdownItem (click)="collapsed = true" (click)="onCoupon()" class="dropdown-item" role="button"><i
      class="ai-card fs-lg opacity-70 me-2"></i>Aplica un card GiftShare</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/cadoul-perfect']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-gift fs-lg opacity-70 me-2"></i>Comanda carduri cadou</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/user/comenzi']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-cart fs-lg opacity-70 me-2"></i>Comenzi</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/user/tranzactii']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-activity fs-lg opacity-70 me-2"></i>Tranzactii & facturi</a>
  <a ngbDropdownItem (click)="collapsed = true" (click)="onDepositClick()" class="dropdown-item" role="button"><i
      class="ai-wallet fs-lg opacity-70 me-2"></i>Adauga fonduri</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/user/creare-eveniment']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-calendar-plus fs-lg opacity-70 me-2"></i>Creeaza eveniment</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/user/evenimente']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-calendar fs-lg opacity-70 me-2"></i>Evenimentele mele</a>

  <div class="dropdown-divider"></div>

  <h6 class="dropdown-header fs-xs fw-medium text-muted text-uppercase pt-3 pb-1">Cont</h6>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/user/profil']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-user-check fs-lg opacity-70 me-2"></i>Profil & facturare</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/user/securitate']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-shield fs-base opacity-70 me-2 mt-n1"></i>Securitate</a>

  <div class="dropdown-divider"></div>

  <a ngbDropdownItem (click)="collapsed = true; logout()" class="dropdown-item" role="button"><i
      class="ai-logout fs-lg opacity-70 me-2"></i>Deconectare</a>

  <div class="dropdown-divider"></div>

  <div class="dropdown-item">
    <div class="form-check form-switch mode-switch" data-bs-toggle="mode">
      <input [(ngModel)]="darkThemeEnabled" (ngModelChange)="collapsed = true; onDarkThemeChange($event)"
        class="form-check-input" type="checkbox" id="theme-mode">
      <label class="form-check-label" for="theme-mode"><i class="ai-sun fs-lg"></i></label>
      <label class="form-check-label" for="theme-mode"><i class="ai-moon fs-lg"></i></label>
    </div>
  </div>
</ng-template>

<ng-template #corporateMenuTemplate>
  <h6 class="dropdown-header fs-xs fw-medium text-muted text-uppercase pb-1">Dashboard</h6>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/corporate/dashboard']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-grid fs-lg opacity-70 me-2"></i>Dashboard</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/corporate/campanii']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-calendar fs-lg opacity-70 me-2"></i>Campaniile mele</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/corporate/creare-campanie']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-calendar-plus fs-lg opacity-70 me-2"></i>Creeaza campanie</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/corporate/tranzactii']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-activity fs-lg opacity-70 me-2"></i>Tranzactii</a>

  <div class="dropdown-divider"></div>

  <h6 class="dropdown-header fs-xs fw-medium text-muted text-uppercase pt-3 pb-1">Cont</h6>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/corporate/profil']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-user-check fs-lg opacity-70 me-2"></i>Profil</a>
  <a ngbDropdownItem (click)="collapsed = true" [routerLink]="['/corporate/securitate']" routerLinkActive="active"
    class="dropdown-item"><i class="ai-shield fs-base opacity-70 me-2 mt-n1"></i>Securitate</a>

  <div class="dropdown-divider"></div>

  <a ngbDropdownItem (click)="collapsed = true; logout()" class="dropdown-item" role="button"><i
      class="ai-logout fs-lg opacity-70 me-2"></i>Deconectare</a>

  <div class="dropdown-divider"></div>

  <div class="dropdown-item">
    <div class="form-check form-switch mode-switch" data-bs-toggle="mode">
      <input class="form-check-input" type="checkbox" id="theme-mode">
      <label class="form-check-label" for="theme-mode"><i class="ai-sun fs-lg"></i></label>
      <label class="form-check-label" for="theme-mode"><i class="ai-moon fs-lg"></i></label>
    </div>
  </div>
</ng-template>
