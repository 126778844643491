import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const interceptedRequest = this.isAbsoluteUrl(request.url)
      ? request
      : request.clone({
        url: `${environment.apiUrl}/${request.url}`
      });
    return next.handle(interceptedRequest);
  }

  private isAbsoluteUrl(url: string) {
    return (url.toUpperCase().indexOf('HTTP://') === 0 ||
      url.toUpperCase().indexOf('HTTPS://') === 0);
  }

  // You might want to bypass the interceptor for root-relative URLs (e.g., '/auth/login' or '/assets/data.json')
  private isRootRelativeUrl(url: string) {
    return url.startsWith('/');
  }
}
