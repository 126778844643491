import { Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { fadeInOutAnimation } from '../../../core/animations';

@Component({
  selector: 'gs-main-layout',
  templateUrl: 'main-layout.component.html',
  animations: [fadeInOutAnimation]
})
export class MainLayoutComponent implements OnInit {
  constructor(private contexts: ChildrenOutletContexts) {
  }

  ngOnInit() { }

  isRouteAnimationEnabled() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'] !== false;
  }
}
