import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

declare var XLSX: any;
declare var jsPDF: any;

@Injectable({
  providedIn: 'root'
})
export class FileExportService {

  constructor() { }

  /**
   * Export tabular data as PDF
   *
   * @param {*} exportData
   * @param {*} filterKeys
   * @param {*} fileName
   * @param {*} [keepColumnKeys=keepColumnData]
   * @memberof FileExportService
   */
  exportAsPdf(exportData: any, filterKeys: any, headers: any, fileName: any) {
    const rows = [];
    const columns = [];

    for (const keys in exportData) {
      if (exportData.hasOwnProperty(keys)) {
        const model = exportData[keys];
        const data = filterKeys.reduce((acc, key) => {
          if (model[key] !== undefined) {
            acc[key] = model[key];
          }
          return acc;
        }, {});

        for (const key of Object.keys(data)) {
          if (isNaN(data[key])) {
            data[key] = this.replaceDiacritics(data[key]);
          }
        }
        rows.push(data);
      }
    }

    for (let i = 0; i < filterKeys.length; i++) {
      const model: any = { title: headers[i], dataKey: filterKeys[i] };
      columns.push(model);
    }

    this.getBase64Image(environment.pdfLogoUrl, function (base64Img) {
      const doc = new jsPDF('p', 'pt', 'a4');
      const totalPagesExp = '{total_pages_count_string}';

      doc.autoTable(columns, rows, {
        didDrawPage: () => {
          if (base64Img) {
            doc.addImage(base64Img, 'JPEG', 220, 20, 150, 30);
          }
        },
        margin: {
          top: 70
        }
      });
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
      }
      doc.save(fileName + '.pdf');
    });

  }

  /**
   * Export tabular data as Excel.
   *
   * @param {*} exportData
   * @param {*} filterKeys
   * @param {*} fileName
   * @param {*} [keepColumnKeys=keepColumnData]
   * @memberof FileExportService
   */
  exportAsExcel(exportData: any, filterKeys: any, headers: any, fileName: any) {
    const rows = [];

    for (const keys in exportData) {
      if (exportData.hasOwnProperty(keys)) {
        const item = exportData[keys];
        const data = filterKeys.reduce((acc, key) => {
          if (item[key] !== undefined) {
            acc[key] = item[key];
          }
          return acc;
        }, {});

        for (const key of Object.keys(data)) {
          if (isNaN(data[key])) {
            data[key] = this.replaceDiacritics(data[key]);
          }
        }
        rows.push(data);
      }
    }

    for (let j = 0; j < headers.length; j++) {
      for (let i = 0; i < rows.length; i++) {
        rows[i][headers[j]] = rows[i][filterKeys[j]];
        delete rows[i][filterKeys[j]];
      }
    }

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'GiftShare');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);

  }

  /**
   * Download the image and return base64
   *
   * @private
   * @param {*} url
   * @param {*} callback
   * @memberof FileExportService
   */
  private getBase64Image(img, callback) {
    const baseImage = new Image();
    baseImage.src = img;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    baseImage.onload = function () {
      canvas.width = baseImage.width;
      canvas.height = baseImage.height;
      ctx.drawImage(baseImage, 0, 0, baseImage.width, baseImage.height);
      callback(canvas.toDataURL('image/jpeg'));
    };
    baseImage.src = img;

  }

  /**
 * Replace diacritics from inputs
 *
 * @private
 * @param {string} input
 * @returns {string}
 * @memberof FileExportService
 */
  private replaceDiacritics(input: string): string {
    const diacritics = 'ĂÂÎȘŞȚŢăâîșşțţ';
    const replacements = 'AAISSTTaaisstt';

    for (let i = 0; i < diacritics.length; i++) {
      input = input.replace(diacritics[i], replacements[i]);
    }

    return input;
  }
}
