import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NgxGaugeType } from 'ngx-gauge/gauge/gauge';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { EventService, ToastService } from '../../../core/services';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'gs-event-list',
  templateUrl: './event-list.component.html',
})
export class EventListComponent implements OnInit, OnDestroy {
  @Input() events: any[] = [];
  @Input() itemsPerPage: number = 6;
  @Input() canShowAdminButtons: boolean = false;
  @Input() canShowContributeButton: boolean = false;
  @Input() canDisplayNoResultPage: boolean = false;
  @Input() canDisplayPagination: boolean = true;

  page: number = 1;

  gaugeType: NgxGaugeType = 'full';
  thick: any = '5';
  gaugeAppendText: any = '%';
  foregroundColor: any = 'rgba(0,164,239,1)';
  backgroundColor: any = 'rgba(246,246,246,1)';

  private subscriptions: Subscription[] = [];

  constructor(
    private eventService: EventService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private route: Router
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Event Pause/Delete Functionality
   */
  onDelete(event: any) {
    if (event.status == 'Active') {
      const modalRef = this.modalService.open(ConfirmComponent);
      modalRef.componentInstance.title = 'Confirma stergerea';
      modalRef.componentInstance.description = event.totalContributionAmount == 0 ? 'Datele asociate evenimentului vor fi permanent sterse si nu pot fi recuperate.'
        : 'Acest eveniment nu poate fi sters pentru ca are fonduri asociate. Doresti oprirea temporara a evenimentului?';
      modalRef.componentInstance.acceptLabel = event.totalContributionAmount == 0 ? 'Sterge' : 'Opreste';
      modalRef.componentInstance.acceptClass = 'btn-danger';
      modalRef.componentInstance.rejectLabel = 'Anuleaza';

      this.subscriptions.push(
        modalRef.componentInstance.done
          .subscribe(result => {
            if (result === 'accept') {
              // Pause or Delete
              event.totalContributionAmount == 0 ? this.deleteEvent(event.code) :
                this.pauseEvent(event.code);
            }
          })
      );

    } else {
      if (event.totalContributionAmount == 0) {
        this.toastService.show('Eroare', 'Evenimentul nu poate fi sters deoarece este finalizat.', 'error');
      } else {
        this.toastService.show('Eroare', 'Evenimentul nu poate fi sters deoarece este finalizat si are fonduri asociate.', 'error');
      }
    }
  }

  /**
   * Event delete functionality
   */
  private deleteEvent(eventCode: string) {
    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.eventService.deleteEvent(eventCode)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe(() => {
          this.toastService.show('Succes', 'Evenimentul a fost sters cu succes.', 'success');
          window.location.reload();
        })
    );
  }

  private pauseEvent(eventCode: string) {
    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.eventService.pauseEvent(eventCode)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe(() => {
          this.toastService.show('Succes', 'Evenimentul a fost oprit temporar.', 'success');
          window.location.reload();
        })
    );
  }

  onPageChanged() {
    this.scroll();
  }

  private scroll() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
