<div class="modal-header border-0">
  <h4 class="modal-title">{{title}}</h4>
  <button (click)="dismiss()" class="btn-close" type="button" aria-label="Close"></button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-0" novalidate autocomplete="off">
  <div class="modal-body py-1">

    <ng-container *ngIf="canShowExistingAddresses">

      <!-- Billing details -->
      <h5>Selecteaza date existente </h5>
      <!-- <label class="form-label">Date de facturare</label> -->
      <div class="row row-cols-1 row-cols-lg-2 g-4">

        <!-- Address PF -->
        <div *ngIf="personalAddress" class="col">
          <div class="card justify-content-between bg-secondary h-100 rounded-3 p-3 p-sm-4">
            <div class="d-flex align-items-center pb-2 mb-1">
              <h3 class="h6 text-nowrap text-truncate mb-0">{{personalAddress.firstName}}
                {{personalAddress.lastName}}
              </h3>
              <span class="badge bg-primary bg-opacity-10 text-primary fs-xs ms-3">Persoana fizica</span>
            </div>
            <p class="mb-0">
              {{personalAddress.street}}<br>
              {{getCity(personalAddress?.cityId)?.name || 'n/a'}},
              {{getCity(personalAddress?.cityId)?.countyName || 'n/a'}}
            </p>
            <div class="mt-3">
              <button (click)="onUseAddressClick(personalAddress)" type="button"
                class="btn btn-sm btn-primary">Selecteaza</button>
            </div>
          </div>
        </div>

        <!-- Address PJ -->
        <div *ngIf="companyAddress" class="col">
          <div class="card justify-content-between bg-secondary h-100 rounded-3 p-3 p-sm-4">
            <div class="d-flex align-items-center pb-2 mb-1">
              <h3 class="h6 text-nowrap text-truncate mb-0">{{companyAddress.company}}</h3>
              <span class="badge bg-success bg-opacity-10 text-success fs-xs ms-3">Persoana
                juridica</span>
            </div>
            <p class="mb-0">
              {{companyAddress?.street || 'n/a'}},<br>
              {{getCity(companyAddress?.cityId)?.name || 'n/a'}},
              {{getCity(companyAddress?.cityId)?.countyName || 'n/a'}}
            </p>
            <div class="mt-3">
              <button (click)="onUseAddressClick(companyAddress)" type="button"
                class="btn btn-sm btn-primary">Selecteaza</button>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

    <h5 class="mt-4">Adauga date noi</h5>

    <div class="row g-4">
      <div class="col-12">
        <!-- <label class="form-label fs-base" for="type">Tipul datelor de facturare</label> -->
        <div class="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
          <input formControlName="type" [value]="'Personal'" type="radio" class="btn-check" id="isPersonalAddress">
          <label class="btn btn-outline-secondary" for="isPersonalAddress">Persoana fizica</label>

          <input formControlName="type" [value]="'Company'" type="radio" class="btn-check" id="isCompanyAddress">
          <label class="btn btn-outline-secondary" for="isCompanyAddress">Persoana juridica</label>
        </div>
      </div>
      <ng-container *ngIf="!isCompanyAddress">
        <div class="col-sm-6">
          <label class="form-label fs-base" for="firstName">Prenume</label>
          <input formControlName="firstName" [ngClass]="{'is-invalid': vs.hasErrors(form, 'firstName')}"
            class="form-control form-control-lg" type="text" placeholder="ex. Ana" id="firstName">
          <div *ngIf="vs.hasErrors(form, 'firstName')" class="invalid-feedback">{{vs.getError(form, 'firstName')}}</div>
        </div>
        <div class="col-sm-6">
          <label class="form-label fs-base" for="lastName">Nume</label>
          <input formControlName="lastName" [ngClass]="{'is-invalid': vs.hasErrors(form, 'lastName')}"
            class="form-control form-control-lg" type="text" placeholder="ex. Popescu" id="lastName">
          <div *ngIf="vs.hasErrors(form, 'lastName')" class="invalid-feedback">{{vs.getError(form, 'lastName')}}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="isCompanyAddress">
        <div class="col-sm-6">
          <label class="form-label fs-base" for="fiscalCode">Cod fiscal</label>
          <div class="input-group input-group-lg p-0"
            style="--ar-input-group-border-radius: var(--ar-border-radius-lg)">
            <input formControlName="fiscalCode" [ngClass]="{'is-invalid': vs.hasErrors(form, 'fiscalCode')}"
              class="form-control" type="text" placeholder="ex. RO12345678" id="fiscalCode" gsUppercase>
            <button (click)="onFiscalCodeSearch()" class="btn btn-sm btn-outline-primary" type="button">Cauta</button>
          </div>
          <div *ngIf="vs.hasErrors(form, 'fiscalCode')" class="invalid-feedback">{{vs.getError(form, 'fiscalCode')}}
          </div>
        </div>
        <div class="col-sm-6">
          <label class="form-label fs-base" for="registrationNumber">Nr. registrul comertului</label>
          <input formControlName="registrationNumber"
            [ngClass]="{'is-invalid': vs.hasErrors(form, 'registrationNumber')}" class="form-control form-control-lg"
            type="text" placeholder="ex. J12/3456/2010" id="registrationNumber" gsUppercase>
          <div *ngIf="vs.hasErrors(form, 'registrationNumber')" class="invalid-feedback">{{vs.getError(form,
            'registrationNumber')}}</div>
        </div>
        <div class="col-12">
          <label class="form-label fs-base" for="company">Nume companie</label>
          <input formControlName="company" [ngClass]="{'is-invalid': vs.hasErrors(form, 'company')}"
            class="form-control form-control-lg" type="text" placeholder="ex. IT INNOVATION SRL" id="company">
          <div *ngIf="vs.hasErrors(form, 'company')" class="invalid-feedback">{{vs.getError(form, 'company')}}</div>
        </div>
      </ng-container>

      <div [ngClass]="{ 'col-sm-6': isCompanyAddress, 'col-12': !isCompanyAddress }">
        <label class="form-label fs-base" for="cityId">Localitate</label>
        <ng-select formControlName="cityId" [items]="cities" [virtualScroll]="true" bindLabel="nameFormatted"
          bindValue="id" [ngClass]="{'is-invalid': vs.hasErrors(form, 'cityId')}" class="form-control form-control-lg"
          placeholder="Cauta numele localitatii (ex. Bucuresti)" id="cityId">
        </ng-select>
        <div *ngIf="vs.hasErrors(form, 'cityId')" class="invalid-feedback">{{vs.getError(form, 'cityId')}}
        </div>
      </div>
      <div *ngIf="isCompanyAddress" class="col-sm-6">
        <label class="form-label fs-base" for="street">Adresa</label>
        <input formControlName="street" [ngClass]="{'is-invalid': vs.hasErrors(form, 'street')}"
          class="form-control form-control-lg" type="text" placeholder="ex. Str. Maria Rosetti nr. 123" id="street">
        <div *ngIf="vs.hasErrors(form, 'street')" class="invalid-feedback">{{vs.getError(form, 'street')}}</div>
      </div>
      <div *ngIf="user" class="col-12">
        <div class="form-check form-switch">
          <input formControlName="canSaveAddress" [ngClass]="{'is-invalid': vs.hasErrors(form, 'canSaveAddress')}"
            type="checkbox" class="form-check-input" id="canSaveAddress">
          <label class="form-check-label" for="canSaveAddress">Salveaza datele pentru facturari ulterioare</label>
          <div *ngIf="vs.hasErrors(form, 'canSaveAddress')" class="invalid-feedback">{{vs.getError(form,
            'canSaveAddress')}}</div>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer border-0">
    <button (click)="dismiss('cancel')" class="btn btn-secondary w-100 w-sm-auto mb-3 mb-sm-0" type="button"
      data-bs-dismiss="modal">Anuleaza</button>
    <button class="btn btn-primary w-100 w-sm-auto ms-sm-3" type="submit">Salveaza</button>
  </div>
</form>
