import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {
  transform(array: any[], query: string): any {
    if (query && array) {
      return array.filter(row => row.fullName.toLowerCase().indexOf(query.toLowerCase()) > -1);
    }
    return array;
  }
}
