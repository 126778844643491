import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthenticationService, Notification, UserProfile } from '../../../core';
import { isCorporateUser, isStandardUser, userImage } from '../../../core/helpers';
import { AuthActions, LocalActions } from '../../../state';
import { CouponModalComponent } from '../coupon-modal/coupon-modal.component';
import { DepositModalComponent } from '../deposit-modal/deposit-modal.component';
import { Router } from '@angular/router';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  selector: 'gs-header',
  templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(state => state.auth.cartCount) cartCount$: Observable<number>;

  isCorporateUser = isCorporateUser;
  isStandardUser = isStandardUser;
  // @Select(state => state.auth.notifications) notifications$: Observable<Notification[]>;

  user: UserProfile;
  notifications: Notification[] = [];
  currentDate: Date = new Date();
  darkThemeEnabled: boolean = false;
  collapsed = true;

  private subscriptions: Subscription[] = [];

  get blogUrl(): string {
    return environment.blogUrl;
  }

  get userImageUrl(): string {
    return userImage(this.user?.image);
  }

  get unreadNotificationCount(): number {
    return this.notifications && this.notifications.reduce((total, x) => (!x.viewDate ? total + 1 : total), 0);
  }

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select<UserProfile>(state => state.auth.user)
        .subscribe(user => {
          this.user = user;
        }),

      this.store.select<Notification[]>(state => state.auth.notifications)
        .subscribe(notifications => {
          this.notifications = notifications;
        }),

      this.store.select<string>(state => state.local.theme)
        .subscribe(theme => {
          this.darkThemeEnabled = theme === 'dark';
          this.setTheme(this.darkThemeEnabled);
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onDarkThemeChange(darkThemeEnabled: boolean) {
    this.setTheme(darkThemeEnabled);
    this.store.dispatch(new LocalActions.SetTheme(darkThemeEnabled ? 'dark' : 'light'));
  }

  private setTheme(darkThemeEnabled: boolean) {
    if (darkThemeEnabled) {
      this.renderer.addClass(this.document.documentElement, 'dark-mode');
    } else {
      this.renderer.removeClass(this.document.documentElement, 'dark-mode');
    }
  }

  getNotificationUserImageUrl(user: UserProfile): string {
    return userImage(user.image);
  }

  getNotificationIconBackground(notification: Notification): string {
    switch (notification.type) {
      case 'Information': return 'bg-faded-info';
      case 'Success': return 'bg-faded-success';
      case 'Warning': return 'bg-faded-warning';
      case 'Error': return 'bg-faded-danger';
      case 'Maintenance': return 'bg-faded-warning';
      case 'UserCreated': return 'bg-faded-info';
      case 'EventCreated': return 'bg-faded-info';
      case 'EventCompleted': return 'bg-faded-success';
      case 'EventInvited': return 'bg-faded-info';
      case 'ContributionCompleted': return 'bg-faded-success';
      case 'ContributionFailed': return 'bg-faded-danger';
      case 'ContributionRefunded': return 'bg-faded-warning';
      case 'DepositCompleted': return 'bg-faded-success';
      case 'DepositFailed': return 'bg-faded-danger';
      case 'DepositRefunded': return 'bg-faded-warning';
      case 'OrderCreated': return 'bg-faded-info';
      case 'OrderCompleted': return 'bg-faded-success';
      case 'OrderFailed': return 'bg-faded-danger';
      case 'CouponCreated': return 'bg-faded-info';
      default: return 'bg-faded-info';
    }
  }

  getNotificationIcon(notification: Notification): string {
    switch (notification.type) {
      case 'Information': return 'ai-circle-info text-info';
      case 'Success': return 'ai-circle-check text-success';
      case 'Warning': return 'ai-triangle-alert text-warning';
      case 'Error': return 'ai-circle-slash text-danger';
      case 'Maintenance': return 'ai-triangle-alert text-warning';
      case 'UserCreated': return 'ai-circle-info text-info';
      case 'EventCreated': return 'ai-circle-info text-info';
      case 'EventCompleted': return 'ai-circle-check text-success';
      case 'EventInvited': return 'ai-circle-info text-info';
      case 'ContributionCompleted': return 'ai-circle-check text-success';
      case 'ContributionFailed': return 'ai-circle-slash text-danger';
      case 'ContributionRefunded': return 'ai-triangle-alert text-warning';
      case 'DepositCompleted': return 'ai-circle-check text-success';
      case 'DepositFailed': return 'ai-circle-slash text-danger';
      case 'DepositRefunded': return 'ai-triangle-alert text-warning';
      case 'OrderCreated': return 'ai-circle-info text-info';
      case 'OrderCompleted': return 'ai-circle-check text-success';
      case 'OrderFailed': return 'ai-circle-slash text-danger';
      case 'CouponCreated': return 'ai-circle-info text-info';
      default: return 'ai-circle-info text-info';
    }
  }

  navigateToNotificationUrl(notification: Notification) {
    let url;

    switch (notification.type) {
      // case 'Information': url = ['/']; break;
      // case 'Success': url = ['/']; break;
      // case 'Warning': url = ['/']; break;
      // case 'Error': url = ['/']; break;
      // case 'Maintenance': url = ['/']; break;
      case 'UserCreated': url = ['/cum-functioneaza']; break;
      // case 'EventCreated': url = ['/']; break;
      case 'EventCompleted': url = ['/eveniment', notification.metadata.eventCode]; break;
      case 'EventInvited': url = ['/eveniment', notification.metadata.eventCode]; break;
      case 'ContributionCompleted': url = ['/contributii', notification.metadata.eventCode]; break;
      // case 'ContributionFailed': url = ['/']; break;
      case 'ContributionRefunded': url = ['/user/tranzactii']; break;
      case 'DepositCompleted': url = ['/user/tranzactii']; break;
      case 'DepositFailed': url = ['/user/tranzactii']; break;
      case 'DepositRefunded': url = ['/user/tranzactii']; break;
      // case 'OrderCreated': url = ['/']; break;
      case 'OrderCompleted': url = ['/user/comenzi']; break;
      case 'OrderFailed': url = ['/user/comenzi']; break;
      // case 'CouponCreated': url = ['/']; break;
    }

    if (url) {
      this.router.navigate(url);
    }
  }

  onNotificationOpenChange(isOpen: boolean) {
    if (isOpen == false) {
      const unread = this.notifications.filter(x => !x.viewDate);
      if (unread.length > 0) {
        this.subscriptions.push(
          this.notificationService.viewNotifications(unread.map(x => x.id))
            .subscribe(() => {
              this.store.dispatch(new AuthActions.SetNotifications(
                this.notifications.map(x => {
                  if (x.viewDate) {
                    return x;
                  }

                  return { ...x, viewDate: new Date() };
                }
                )));
            })
        );
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  onCoupon() {
    const modalRef = this.modalService.open(CouponModalComponent, { backdrop: 'static', });
    modalRef.componentInstance.title = 'Aplica un card cadou GiftShare';
  }

  onDepositClick() {
    const modalRef = this.modalService.open(DepositModalComponent, { backdrop: 'static', });
    modalRef.componentInstance.title = 'Adauga fonduri';
  }
}
