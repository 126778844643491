<main class="page-wrapper" [@routeAnimations]="o.activatedRouteData">

  <gs-header></gs-header>

  <!-- Page content-->
  <router-outlet #o="outlet"></router-outlet>

</main>

<gs-footer [containerClass]="o.activatedRouteData?.footerClass"></gs-footer>

<gs-scroll-button></gs-scroll-button>
