/**
 * Convert a base64 Data URL to File
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs
 * https://developer.mozilla.org/en-US/docs/Web/API/File
 *
 * @export function
 * @param {string} base64File
 * @param {string} [contentType='']
 * @returns {Blob}
 * @memberof Utils
 */
export function dataUrlToBlob(base64File: string, contentType = ''): Blob {
  const split = base64File.split(',');
  const type = split[0].replace('data:', '').replace(';base64', '');
  const byteString = atob(split[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: contentType || type });
}

/**
 * Convert base64 Data URL to Uint8Array
 *
 * @export function
 * @param {string} base64File
 * @returns {Uint8Array}
 * @memberof Utils
 */
export function dataUrlToArray(base64File: string): Uint8Array {
  const split = base64File.split(',');
  const byteString = atob(split[1]);
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return ia;
}

/**
 * Get mime type from base64 Data URL
 *
 * @export function
 * @param {string} base64File
 * @returns {string}
 * @memberof Utils
 */
export function getFileTypeFromDataUrl(base64File: string): string {
  const split = base64File.split(',');
  return split[0].replace('data:', '').replace(';base64', '');
}

/**
 * Capitalize word
 *
 * @export function
 * @param {string} value
 * @returns
 * @memberof GenericUtils
 */
export function capitalize(value: string) {
  return value && value.charAt(0).toUpperCase() + value.slice(1);
}

