import { Address } from "./address.model";

export interface UserProfile {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  name: string;
  birthDate?: Date;
  gender?: number;
  cityId?: number;
  phone: string;
  email: string;
  image: string;
  // description: string;
  hasNewsletter?: boolean;
  walletAmount: number;
  walletAmountUpdatedDate?: Date;
  isActive: boolean;
  registrationSource: string;
  createdDate?: Date;
  roles: string[];
  hobbies: any[];
  addresses: Address[];
}

export interface UserProfileUpdate {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender?: number;
  cityId: number;
  phone: string;
  hasNewsletter?: boolean;
  hobbyIds: number[];
  image: string;
}
