import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';

import { Subscription } from 'rxjs';

@Component({
  selector: 'gs-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit, OnDestroy {
  @Input() videoId: string;
  @ViewChild('player') player: YouTubePlayer;

  videoLoaded: boolean = false;
  playerVars: YT.PlayerVars = {
    // autoplay: 1
  };

  private subscriptions: Subscription[] = [];

  get thumbnail(): string {
    return this.videoId && `https://i.ytimg.com/vi/${this.videoId}/maxresdefault.jpg`;
  }

  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  load() {
    if (!this.videoLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.videoLoaded = true;
    }

    this.changeDetector.detectChanges();
    this.subscriptions.push(
      this.player.ready.subscribe((x) => {
        x.target.playVideo();
      })
    );

    // setTimeout(() => {
    //   debugger
    // }, 100);
  }
}
