import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NewsletterService, ToastService, ValidationService } from '../../../core';
import { GiftShareValidators } from '../../validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { LocalActions } from '../../../state';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';

@Component({
  selector: 'gs-footer',
  templateUrl: 'footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() containerClass: string;

  currentDate: Date = new Date();

  form: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.maxLength(256), GiftShareValidators.email]],
  });

  get blogUrl(): string {
    return environment.blogUrl;
  }

  private subscriptions: Subscription[] = [];

  constructor(
    private newsletterService: NewsletterService,
    private formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private store: Store,
    public vs: ValidationService,
  ) {

  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onReportBugClick() {
    this.modalService.open(FeedbackModalComponent, { backdrop: 'static' });
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    const model = {
      ...this.form.value,
      source: 'HOMEPAGE'
    };

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.newsletterService.createSubscription(model)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe(() => {
          this.toastService.show('Succes', 'Abonare a fost facuta cu succes.', 'success');
        })
    );
  }

  onCookieConsentClick() {
    this.store.dispatch(new LocalActions.SetCanDisplayCookieBanner(true));
  }
}
