
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Order } from '../../core/models';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {
  }

  getCartItems(): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}/cart-items`);
  }

  createCartItem(model: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/cart-items`, model);
  }

  updateCartItem(id: number, model: any): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/cart-items/${id}`, model);
  }

  deleteCartItem(id: number): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/cart-items/${id}`);
  }

  confirmOrder(model: Order): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/confirm`, model);
  }

  getOrders(): Observable<any> {
    return this.http.get<any>(`${this.endpoint}`);
  }

  getGiftCardImageTemplates(): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}/image-templates`);
  }

  downloadFile(url: string, fileName: string): Observable<never> {
    return this.http.get(url, {
      headers: {
        Accept: 'image/*,application/pdf,*/*'
      },
      responseType: 'blob'
    })
      .pipe(
        mergeMap((data: Blob) => {
          this.downloadBlob(data, fileName);

          return EMPTY;
        })
      );
  }

  /**
   * Generic tigger saveAs browser modal
   *
   * @protected
   * @param {string} url
   * @memberof BaseService
   */
  downloadBlob(blob: Blob, fileName: string) {
    // const blob = new Blob([data], { type: type });
    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = objectUrl;
    link.download = fileName;
    link.click();
    // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    // Remove link
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(objectUrl);
      link.remove();
    }, 100);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/orders`;
  }
}
