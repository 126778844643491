import { Injectable } from '@angular/core';
import { State, Action, StateContext, NgxsOnInit, NgxsAfterBootstrap, NgxsSimpleChange, NgxsOnChanges } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonService, CorporateEventService, EventService, EventTypeService, OrderService, PartnerService, AccountService, SettingsService, SystemStatistics, VoucherService, City } from '../core';

/* Actions */
export namespace StaticDataActions {

  const ActionPrefix: string = '[StaticData] ';

  export class GetSystemStatistics {
    static readonly type = `${ActionPrefix}GetSystemStatistics`;
    constructor() { }
  }

  export class GetPublicSettings {
    static readonly type = `${ActionPrefix}GetPublicSettings`;
    constructor() { }
  }

  export class GetVouchers {
    static readonly type = `${ActionPrefix}GetVouchers`;
    constructor() { }
  }

  export class GetHobbies {
    static readonly type = `${ActionPrefix}GetHobbies`;
    constructor() { }
  }

  export class GetCities {
    static readonly type = `${ActionPrefix}GetCities`;
    constructor() { }
  }

  export class GetCategories {
    static readonly type = `${ActionPrefix}GetCategories`;
    constructor() { }
  }

  export class GetLocations {
    static readonly type = `${ActionPrefix}GetLocations`;
    constructor() { }
  }

  export class GetGiftCardImageTemplates {
    static readonly type = `${ActionPrefix}GetGiftCardImageTemplates`;
    constructor() { }
  }

  export class GetReportCauses {
    static readonly type = `${ActionPrefix}GetReportCauses`;
    constructor() { }
  }

  export class GetEventTypes {
    static readonly type = `${ActionPrefix}GetEventTypes`;
    constructor() { }
  }

  export class GetCorporateImageTemplates {
    static readonly type = `${ActionPrefix}GetCorporateImageTemplates`;
    constructor() { }
  }
}

/* State interface */
export interface StaticDataStateModel {
  systemStatistics: SystemStatistics;
  publicSettings: any;
  vouchers: any[];
  hobbies: any[];
  cities: City[];
  categories: any[];
  locations: any[];
  giftCardImageTemplates: any[];
  reportCauses: any[];
  eventTypes: any[];
  corporateImageTemplates: any[];
}

/* Default state */
const defaults: StaticDataStateModel = {
  systemStatistics: undefined,
  publicSettings: undefined,
  vouchers: undefined,
  hobbies: undefined,
  cities: undefined,
  categories: undefined,
  locations: undefined,
  giftCardImageTemplates: undefined,
  reportCauses: undefined,
  eventTypes: undefined,
  corporateImageTemplates: undefined
}

@State<StaticDataStateModel>({
  name: 'staticData',
  defaults: defaults
})
@Injectable()
export class StaticDataState implements NgxsOnInit, NgxsAfterBootstrap, NgxsOnChanges {

  constructor(
    private commonService: CommonService,
    private settingsService: SettingsService,
    private voucherService: VoucherService,
    private accountService: AccountService,
    private partnerService: PartnerService,
    private orderService: OrderService,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private corporateEventService: CorporateEventService,
  ) {
  }

  //Called before ngxsOnInit() and whenever state changes.
  ngxsOnChanges(change: NgxsSimpleChange) {
    // console.log('prev state', change.previousValue);
    // console.log('next state', change.currentValue);
  }

  //Called once, after the first ngxsOnChanges() and before the APP_INITIALIZER token is resolved.
  ngxsOnInit(ctx?: StateContext<StaticDataState>) {
  }

  //Called once, after the root view and all its children have been rendered.
  ngxsAfterBootstrap(ctx?: StateContext<StaticDataState>): void {
  }

  @Action(StaticDataActions.GetSystemStatistics)
  getSystemStatistics(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetSystemStatistics) {
    const state = ctx.getState();
    if (state.systemStatistics) {
      return EMPTY;
    }

    return this.commonService.getStatistics()
      .pipe(
        tap(result => {
          ctx.patchState({
            systemStatistics: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetPublicSettings)
  getPublicSettings(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetPublicSettings) {
    const state = ctx.getState();
    if (state.publicSettings) {
      return EMPTY;
    }

    return this.settingsService.getPublicSettings()
      .pipe(
        tap(result => {
          ctx.patchState({
            publicSettings: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetVouchers)
  getVouchers(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetVouchers) {
    const state = ctx.getState();
    if (state.vouchers) {
      return EMPTY;
    }

    return this.voucherService.getVouchers()
      .pipe(
        tap(result => {
          ctx.patchState({
            vouchers: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetHobbies)
  getHobbies(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetHobbies) {
    const state = ctx.getState();
    if (state.hobbies) {
      return EMPTY;
    }

    return this.accountService.getHobbies()
      .pipe(
        tap(result => {
          ctx.patchState({
            hobbies: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetCities)
  getCities(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetCities) {
    const state = ctx.getState();
    if (state.cities) {
      return EMPTY;
    }

    return this.accountService.getCities()
      .pipe(
        tap(result => {
          ctx.patchState({
            cities: result //[...result.map(x => ({ ...x, formatted: `${x.name}, ${x.countyName}`}))]
          });
        })
      );
  }

  @Action(StaticDataActions.GetCategories)
  getCategories(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetCategories) {
    const state = ctx.getState();
    if (state.categories) {
      return EMPTY;
    }

    return this.partnerService.getCategories()
      .pipe(
        tap(result => {
          ctx.patchState({
            categories: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetLocations)
  getLocations(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetLocations) {
    const state = ctx.getState();
    if (state.locations) {
      return EMPTY;
    }

    return this.partnerService.getLocations()
      .pipe(
        tap(result => {
          ctx.patchState({
            locations: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetGiftCardImageTemplates)
  getGiftCardImageTemplates(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetGiftCardImageTemplates) {
    const state = ctx.getState();
    if (state.giftCardImageTemplates) {
      return EMPTY;
    }

    return this.orderService.getGiftCardImageTemplates()
      .pipe(
        tap(result => {
          ctx.patchState({
            giftCardImageTemplates: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetReportCauses)
  getReportCauses(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetReportCauses) {
    const state = ctx.getState();
    if (state.reportCauses) {
      return EMPTY;
    }

    return this.eventService.getReportCauses()
      .pipe(
        tap(result => {
          ctx.patchState({
            reportCauses: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetEventTypes)
  getEventTypes(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetEventTypes) {
    const state = ctx.getState();
    if (state.eventTypes) {
      return EMPTY;
    }

    return this.eventTypeService.getEventTypes()
      .pipe(
        tap(result => {
          ctx.patchState({
            eventTypes: result
          });
        })
      );
  }

  @Action(StaticDataActions.GetCorporateImageTemplates)
  getCorporateImageTemplates(ctx: StateContext<StaticDataStateModel>, action: StaticDataActions.GetCorporateImageTemplates) {
    const state = ctx.getState();
    if (state.corporateImageTemplates) {
      return EMPTY;
    }

    return this.corporateEventService.getCorporateImageTemplates()
      .pipe(
        tap(result => {
          ctx.patchState({
            corporateImageTemplates: result
          });
        })
      );
  }
}
