import { Component, OnInit } from '@angular/core';
import { fadeInOutAnimation } from '../../../core/animations';

@Component({
  selector: 'gs-blank-layout',
  templateUrl: 'blank-layout.component.html',
  animations: [fadeInOutAnimation]
})
export class BlankLayoutComponent implements OnInit {
  constructor() {
  }

  ngOnInit() { }

}
