<div class="modal-header border-0">
  <h4 class="modal-title">{{title}}</h4>
  <button (click)="dismiss()" class="btn-close" type="button" aria-label="Close"></button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-0" novalidate autocomplete="off">
  <div class="modal-body">
    <div class="row g-4">
      <ng-container *ngIf="!isCompanyAddress">
        <div class="col-sm-6">
          <label class="form-label fs-base" for="firstName">Prenume</label>
          <input formControlName="firstName" [ngClass]="{'is-invalid': vs.hasErrors(form, 'firstName')}"
            class="form-control form-control-lg" type="text" placeholder="ex. Ana" id="firstName">
          <div *ngIf="vs.hasErrors(form, 'firstName')" class="invalid-feedback">{{vs.getError(form, 'firstName')}}</div>
        </div>
        <div class="col-sm-6">
          <label class="form-label fs-base" for="lastName">Nume</label>
          <input formControlName="lastName" [ngClass]="{'is-invalid': vs.hasErrors(form, 'lastName')}"
            class="form-control form-control-lg" type="text" placeholder="ex. Popescu" id="lastName">
          <div *ngIf="vs.hasErrors(form, 'lastName')" class="invalid-feedback">{{vs.getError(form, 'lastName')}}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="isCompanyAddress">
        <div class="col-sm-6">
          <label class="form-label fs-base" for="fiscalCode">Cod fiscal</label>
          <div class="input-group input-group-lg p-0" style="--ar-input-group-border-radius: var(--ar-border-radius-lg)">
            <input formControlName="fiscalCode" [ngClass]="{'is-invalid': vs.hasErrors(form, 'fiscalCode')}"
              class="form-control" type="text" placeholder="ex. RO12345678" id="fiscalCode" gsUppercase>
            <button (click)="onFiscalCodeSearch()" class="btn btn-sm btn-outline-primary" type="button">Cauta</button>
          </div>
          <div *ngIf="vs.hasErrors(form, 'fiscalCode')" class="invalid-feedback">{{vs.getError(form, 'fiscalCode')}}
          </div>
        </div>
        <div class="col-sm-6">
          <label class="form-label fs-base" for="registrationNumber">Nr. registrul comertului</label>
          <input formControlName="registrationNumber"
            [ngClass]="{'is-invalid': vs.hasErrors(form, 'registrationNumber')}" class="form-control form-control-lg"
            type="text" placeholder="ex. J12/3456/2010" id="registrationNumber" gsUppercase>
          <div *ngIf="vs.hasErrors(form, 'registrationNumber')" class="invalid-feedback">{{vs.getError(form,
            'registrationNumber')}}</div>
        </div>
        <div class="col-12">
          <label class="form-label fs-base" for="company">Nume companie</label>
          <input formControlName="company" [ngClass]="{'is-invalid': vs.hasErrors(form, 'company')}"
            class="form-control form-control-lg" type="text" placeholder="ex. IT INNOVATION SRL" id="company">
          <div *ngIf="vs.hasErrors(form, 'company')" class="invalid-feedback">{{vs.getError(form, 'company')}}</div>
        </div>
      </ng-container>

      <!-- <div class="col">
        <label class="form-label fs-base" for="county">Judet</label>
        <input formControlName="county" [ngClass]="{'is-invalid': vs.hasErrors(form, 'county')}"
          class="form-control form-control-lg" type="text" placeholder="ex. Timis" id="county">
        <div *ngIf="vs.hasErrors(form, 'county')" class="invalid-feedback">{{vs.getError(form, 'county')}}</div>
      </div> -->
      <div [ngClass]="{ 'col-sm-6': isCompanyAddress, 'col-12': !isCompanyAddress }">
        <label class="form-label fs-base" for="cityId">Localitate</label>
        <ng-select formControlName="cityId" [items]="cities" [virtualScroll]="true" bindLabel="nameFormatted"
          bindValue="id" [ngClass]="{'is-invalid': vs.hasErrors(form, 'cityId')}" class="form-control form-control-lg"
          placeholder="Cauta numele localitatii (ex. Bucuresti)" id="cityId">
        </ng-select>
        <div *ngIf="vs.hasErrors(form, 'cityId')" class="invalid-feedback">{{vs.getError(form, 'cityId')}}
        </div>
      </div>
      <div *ngIf="isCompanyAddress" class="col-sm-6">
        <label class="form-label fs-base" for="street">Adresa</label>
        <input formControlName="street" [ngClass]="{'is-invalid': vs.hasErrors(form, 'street')}"
          class="form-control form-control-lg" type="text" placeholder="ex. Str. Maria Rosetti nr. 123" id="street">
        <div *ngIf="vs.hasErrors(form, 'street')" class="invalid-feedback">{{vs.getError(form, 'street')}}</div>
      </div>
      <div class="col-12">
        <div class="form-check form-switch">
          <input formControlName="isDefault" [ngClass]="{'is-invalid': vs.hasErrors(form, 'isDefault')}" type="checkbox"
            class="form-check-input" id="isDefault">
          <label class="form-check-label" for="isDefault">Foloseste ca adresa de facturare implicita</label>
          <div *ngIf="vs.hasErrors(form, 'isDefault')" class="invalid-feedback">{{vs.getError(form, 'isDefault')}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button (click)="dismiss('cancel')" class="btn btn-secondary w-100 w-sm-auto mb-3 mb-sm-0" type="button"
      data-bs-dismiss="modal">Anuleaza</button>
    <button class="btn btn-primary w-100 w-sm-auto ms-sm-3" type="submit">Salveaza</button>
  </div>
</form>
