import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { isBrowserIE } from './app/core/helpers';

// if (supportedBrowsers.test(navigator.userAgent)) {
if (!isBrowserIE()) {

  // if (environment.production) {
  //    console.log = function (a, b, c, d, e, f, g, h, i, j, k, l) { return false; };
  // }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => console.log(`Bootstrap success`))
    .catch(err => console.error(err));

} else {
  window.location.href = "unsupported.html"
}
