import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[gsFocusFirstInvalidInput]'
})
export class FocusFirstInvalidInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('submit', ['$event'])
  onSubmit() {
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    if (invalidElements.length > 0 &&
      invalidElements[0] instanceof HTMLInputElement) {
      invalidElements[0].focus();
    }
  }
}
