import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CommonService, Consent, ToastService, ValidationService } from '../../../core';
import { catchError, EMPTY, finalize, Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocalActions } from '../../../state';

@Component({
  selector: 'gs-cookie-banner',
  templateUrl: './cookie-banner.component.html'
})
export class CookieBannerComponent implements OnInit, OnDestroy {
  form: FormGroup = this.formBuilder.group({
    isNecessaryAllowed: [{ value: true, disabled: true }, [Validators.requiredTrue]],
    isPreferencesAllowed: [false, [Validators.required]],
    isStatisticsAllowed: [false, [Validators.required]],
    isMarketingAllowed: [false, [Validators.required]],
    isUnclassifiedAllowed: [true, [Validators.required]],
  });

  activeTabId = 1;

  private subscriptions: Subscription[] = [];

  constructor(
    private commonService: CommonService,
    private toastService: ToastService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private store: Store,
    public vs: ValidationService
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select<Consent>(state => state.local.consent)
        .subscribe(consent => {
          if (consent) {
            this.form.patchValue({
              isPreferencesAllowed: consent.isPreferencesAllowed,
              isStatisticsAllowed: consent.isStatisticsAllowed,
              isMarketingAllowed: consent.isMarketingAllowed,
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    const consent = {
      ...this.form.getRawValue(),
      createdDate: new Date()
    } as Consent;

    this.createConsent(consent);
  }

  onAllowAllCookiesClick() {
    this.createConsent({
      isNecessaryAllowed: true,
      isPreferencesAllowed: true,
      isStatisticsAllowed: true,
      isMarketingAllowed: true,
      createdDate: new Date()
    } as Consent);
  }

  onCustomCookiesClick() {
    if (this.activeTabId != 2) {
      this.activeTabId = 2;
    } else {
      this.onSubmit();
    }
  }

  private createConsent(consent: Consent) {
    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.commonService.consent(consent)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return EMPTY;
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        ).subscribe(() => {
          this.store.dispatch(new LocalActions.SetCookiePolicy(consent));
        })
    );
  }

}
