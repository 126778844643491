import { Directive, ElementRef,  HostListener, Input } from '@angular/core';

@Directive({
  selector: '[gsRegexInput]'
})
export class RegexInputDirective {
  @Input() regex?: string | RegExp;
  inputElement: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    this.inputElement = elRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent) {
    const initalValue = this.inputElement.value;
    const newValue =  initalValue.replace(this.regex, '');
    if ( initalValue !== newValue) {
      this.inputElement.value = newValue;
      event.stopPropagation();
    }
  }
}
