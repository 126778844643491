import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

/**
 * https://scotch.io/@ibrahimalsurkhi/match-password-validation-with-angular-2
 * https://stackoverflow.com/questions/48350506/how-to-validate-password-strength-with-angular-5-validator-pattern
 *
 * @export
 * @class PasswordValidator
 */
export class PasswordValidator {
  static strong(control: FormControl): ValidationResult {
    if (control.value) {
      const hasNumber = /\d/.test(control.value);
      const hasUpper = /[A-Z]/.test(control.value);
      const hasLower = /[a-z]/.test(control.value);
      const hasNonAlphanumeric = /[^a-zA-Z0-9]/.test(control.value);
      const hasLength = control.value.length >= 8;
      if (!hasNumber || !hasUpper || !hasLower || !hasLength || !hasNonAlphanumeric) {
        return {
          ...(!hasLower && { requiredLower: true }),
          ...(!hasUpper && { requiredUpper: true }),
          ...(!hasNumber && { requiredNumber: true }),
          ...(!hasNonAlphanumeric && { requireNonAlphanumeric: true }),
          ...(!hasLength && { requiredLength: true }),
        }
      }
    }
    return null;
  }

  static matchPassword(control: FormControl): ValidationResult {
    if (control.parent?.value?.password && control.value) {
      return control.parent.value.password === control.value ? null : { matchPassword: true };
    }

    return null;
  }
}
