<div class="modal-header border-0">
  <h4 class="modal-title">{{title}}</h4>
  <!-- <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button> -->
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-0" novalidate autocomplete="off">
  <div class="modal-body">
    <div class="alert d-flex alert-warning" role="alert">
      <i class="ai-triangle-alert fs-xl pe-1 me-2"></i>
      <div>Pentru a putea utiliza toate functiile GiftShare, te rugam sa completezi datele lipsa din <a
          [routerLink]="['/user/profil']" class="alert-link">profil</a>.
      </div>
    </div>
    <div class="row row-cols-1 g-4">
      <div *ngIf="isFirstNameMissing" class="col">
        <label class="form-label fs-base" for="firstName">Prenume</label>
        <input formControlName="firstName" [ngClass]="{'is-invalid': vs.hasErrors(form, 'firstName')}"
          class="form-control form-control-lg" type="text" placeholder="ex. Ana" id="firstName">
        <div *ngIf="vs.hasErrors(form, 'firstName')" class="invalid-feedback">{{vs.getError(form, 'firstName')}}</div>
      </div>
      <div *ngIf="isLastNameMissing" class="col">
        <label class="form-label fs-base" for="lastName">Nume</label>
        <input formControlName="lastName" [ngClass]="{'is-invalid': vs.hasErrors(form, 'lastName')}"
          class="form-control form-control-lg" type="text" placeholder="ex. Popescu" id="lastName">
        <div *ngIf="vs.hasErrors(form, 'lastName')" class="invalid-feedback">{{vs.getError(form, 'lastName')}}</div>
      </div>
      <!-- <div *ngIf="isEmailMissing" class="col">
        <label class="form-label fs-base" for="email">Email</label>
        <input formControlName="email" [ngClass]="{'is-invalid': vs.hasErrors(form, 'email')}"
          class="form-control form-control-lg" type="email" placeholder="ex. ana.popescu@gmail.com" id="email">
        <div *ngIf="vs.hasErrors(form, 'email')" class="invalid-feedback">{{vs.getError(form, 'email')}}
        </div>
      </div> -->
      <div *ngIf="isCityMissing" class="col">
        <label class="form-label fs-base" for="cityId">Localitate</label>
        <ng-select formControlName="cityId" [items]="cities" [virtualScroll]="true" bindLabel="nameFormatted" bindValue="id"
          [ngClass]="{'is-invalid': vs.hasErrors(form, 'cityId')}" class="form-control form-control-lg"
          placeholder="Cauta numele localitatii (ex. Bucuresti)" id="cityId">
        </ng-select>
        <div *ngIf="vs.hasErrors(form, 'cityId')" class="invalid-feedback">{{vs.getError(form, 'cityId')}}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <!-- <button (click)="close()" class="btn btn-secondary w-100 w-sm-auto mb-3 mb-sm-0" type="button"
      data-bs-dismiss="modal">Anuleaza</button> -->
    <button class="btn btn-primary w-100 w-sm-auto ms-sm-3" type="submit">Salveaza</button>
  </div>
</form>
