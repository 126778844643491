
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AddressUpsert, City, UserProfile, UserProfileUpdate } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(private http: HttpClient) {
  }

  getCurrentUser(): Observable<UserProfile> {
    return this.http.get<any>(environment.apiUrl + '/accounts/me');
  }

  register(model: any) {
    return this.http.post<any>(environment.apiUrl + '/accounts', model);
  }

  registerOnContribution(model: any) {
    return this.http.post<any>(environment.apiUrl + '/accounts/register-contribution', model);
  }

  confirmEmail(code: string, token: string) {
    const model = {
      code: code,
      token: token
    };
    return this.http.post<any>(environment.apiUrl + '/accounts/confirm', model);
  }

  forgotPassword(email: string) {
    const model = {
      email: email
    };
    return this.http.post<any>(`${this.endpoint}/forgot-password`, model);
  }

  resetPassword(model: any) {
    return this.http.post<any>(`${this.endpoint}/reset-password`, model);
  }

  changePassword(model: any) {
    return this.http.post<any>(`${this.endpoint}/change-password`, model);
  }

  lockUser(code: string) {
    return this.http.post<any>(`${this.endpoint}/${code}/lock`, {});
  }

  updateUser(code: string, model: UserProfileUpdate) {
    return this.http.put<any>(`${this.endpoint}/${code}`, model);
  }

  deleteUser(code: string) {
    return this.http.delete<any>(`${this.endpoint}/${code}`);
  }

  getEmail(code: string) {
    return this.http.get<any>(`${this.endpoint}/${code}/email`);
  }

  getTransactions() {
    return this.http.get<any>(`${this.endpoint}/transactions`);
  }

  getCorporateTransactions() {
    return this.http.get<any>(`${this.endpoint}/corporate-transactions`);
  }

  getTotalTransactions() {
    return this.http.get<any>(`${this.endpoint}/total-transactions`);
  }

  getUserEventStatistics() {
    return this.http.get<any>(`${this.endpoint}/statistics`);
  }

  getHobbies(): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}/hobbies`);
  }

  getCities(): Observable<City[]> {
    return this.http.get<any>(`${this.endpoint}/cities`);
  }

  upsertAddress(code: string, model: AddressUpsert) {
    return this.http.post<any>(`${this.endpoint}/${code}/address`, model);
  }

  getCompanyDetails(fiscalCode: string) {
    return this.http.get<any>(`${this.endpoint}/companies`, { params: { fiscalCode } } );
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/accounts`;
  }
}
