import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[gsDecimalInput]',
})
export class DecimalInputDirective implements OnChanges {
  @Input() decimal = false;
  @Input() decimalSeparator = '.';
  @Input() min = -Infinity;
  @Input() max = Infinity;

  private decimalRegex = new RegExp(`[^0-9${this.decimalSeparator}]`, 'g');
  private integerRegex = new RegExp(`[^0-9]`, 'g');

  get regex(): RegExp {
    return this.decimal ? this.decimalRegex : this.integerRegex;
  }

  constructor(private formControl: NgControl) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.min) {
      const maybeMin = Number(this.min);
      this.min = isNaN(maybeMin) ? -Infinity : maybeMin;
    }

    if (changes.max) {
      const maybeMax = Number(this.max);
      this.max = isNaN(maybeMax) ? Infinity : maybeMax;
    }

    if (changes.decimalSeparator) {
      this.decimalRegex = new RegExp(`[^0-9${this.decimalSeparator}]`, 'g');
    }
  }

  @HostListener('input', ['$event'])
  onInputChange(e: InputEvent) {
    const el: HTMLInputElement = e.target as HTMLInputElement;

    let value = 0;
    try {
      value = this.computeValidNumber(el.value);
    } catch {
      value = 0;
    } finally {
      const newValue = value ? value.toString() : '';
      if (el.value !== newValue) {
        this.formControl.control.setValue(newValue, { emit: false });
      }
    }
  }

  /**
   * Find correct number
   *
   * @private
   * @param {string} input
   * @returns {number}
   * @memberof DecimalInputDirective
   */
  private computeValidNumber(input: string): number {
    if (!input) {
      return 0;
    }

    input = this.sanitizeInput(input);

    // Check for extra decimal separators
    if (this.decimal) {
      let numberParts: string[] = input.split(this.decimalSeparator);
      if (numberParts.length > 2) {
        numberParts = numberParts.slice(0, 2);
      }
      if (numberParts.length > 1) {
        if (numberParts[1].length > 2) {
          numberParts[1] = numberParts[1].substring(0, 2);
        }
        input = numberParts.join(this.decimalSeparator)
      }
    }

    const newNumber = Number(input);
    if (isNaN(newNumber)) {
      return 0;
    }

    if (newNumber > this.max) {
      return this.max;
    }

    if (newNumber < this.min) {
      return this.min;
    }

    return newNumber;
  }

  private sanitizeInput(input: string): string {
    const result = input.replace(this.regex, '');
    return result;
  }

  // private getSelection(): string {
  //   return this.el.nativeElement.value.substring(
  //     this.el.nativeElement.selectionStart,
  //     this.el.nativeElement.selectionEnd
  //   );
  // }
}
