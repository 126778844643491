
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommentService {

  constructor(private http: HttpClient) {
  }

  getComment() {
    return this.http.get<any>(`${this.endpoint}`);
  }

  createComment(model: any) {
    return this.http.post<any>(`${this.endpoint}`, model);
  }

  createReply(commentId: number, model: any) {
    return this.http.post<any>(`${this.endpoint}/${commentId}/replies`, model);
  }

  deleteReply(commentId: number, replyId: number) {
    return this.http.delete<any>(`${this.endpoint}/${commentId}/replies/${replyId}`);
  }

  deleteComment(id: number) {
    return this.http.delete<any>(`${this.endpoint}/${id}`);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/comments`;
  }
}
