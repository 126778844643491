
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventTypeService {
  constructor(private http: HttpClient) {
  }

  getEventTypes(): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}`);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/event-types`;
  }
}
