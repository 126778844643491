
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Consent } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  constructor(private http: HttpClient) {
  }

  sendEmail(model: any) {
    return this.http.post<any>(`${this.endpoint}/contact`, model);
  }

  sendBugReport(model: any) {
    return this.http.post<any>(environment.apiUrl + '/common/report', model);
  }

  uploadImage(files: FormData, folder: string) {
    return this.http.post<any>(`${this.endpoint}/image-upload?type=${folder}`, files);
  }

  getStatistics(): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/system-statistics`);
  }

  consent(model: Consent) {
    return this.http.post<any>(`${this.endpoint}/consent`, model);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/common`;
  }
}
