import { Injectable } from '@angular/core';
import { State, StateContext, NgxsOnInit, NgxsAfterBootstrap, NgxsSimpleChange, NgxsOnChanges, Action } from '@ngxs/store';
import { Consent, GtagService } from '../core';

/* Actions */
export namespace LocalActions {

  const ActionPrefix: string = '[Local] ';

  export class SetTheme {
    static readonly type = `${ActionPrefix}SetTheme`;
    constructor(public theme: string) { }
  }

  export class SetCookiePolicy {
    static readonly type = `${ActionPrefix}SetCookiePolicy`;
    constructor(public consent: Consent) { }
  }

  export class SetCanDisplayCookieBanner {
    static readonly type = `${ActionPrefix}SetCanDisplayCookieBanner`;
    constructor(public canDisplayCookieBanner: boolean) { }
  }

  export class ResetCookiePolicy {
    static readonly type = `${ActionPrefix}ResetCookiePolicy`;
    constructor() { }
  }

  export class UpdateGoogleConsent {
    static readonly type = `${ActionPrefix}UpdateGoogleConsent`;
    constructor(public consent: Consent) { }
  }

  export class SetLastContribution {
    static readonly type = `${ActionPrefix}SetLastContribution`;
    constructor(public lastContributionId: string) { }
  }
}

/* State interface */
export interface LocalStateModel {
  theme: string;
  consent: Consent | undefined;
  canDisplayCookieBanner: boolean;
  lastContributionId: string;
}

/* Default state */
const defaults: LocalStateModel = {
  theme: 'light',
  consent: undefined,
  canDisplayCookieBanner: false,
  lastContributionId: undefined
}

@State<LocalStateModel>({
  name: 'local',
  defaults: defaults
})
@Injectable()
export class LocalState implements NgxsOnInit, NgxsAfterBootstrap, NgxsOnChanges {

  constructor(
    private gtagService: GtagService
  ) {
  }

  //Called before ngxsOnInit() and whenever state changes.
  ngxsOnChanges(change: NgxsSimpleChange) {
    // console.log('prev state', change.previousValue);
    // console.log('next state', change.currentValue);
  }

  //Called once, after the first ngxsOnChanges() and before the APP_INITIALIZER token is resolved.
  ngxsOnInit(ctx?: StateContext<LocalState>) {
  }

  //Called once, after the root view and all its children have been rendered.
  ngxsAfterBootstrap(ctx?: StateContext<LocalState>): void {
  }

  @Action(LocalActions.SetTheme)
  setLocal(ctx: StateContext<LocalStateModel>, action: LocalActions.SetTheme) {
    ctx.patchState({
      theme: action.theme
    });
  }

  @Action(LocalActions.SetCookiePolicy)
  setCookiePolicy(ctx: StateContext<LocalStateModel>, action: LocalActions.SetCookiePolicy) {
    ctx.patchState({
      consent: action.consent
    });
    //ctx.dispatch(new LocalActions.UpdateGoogleConsent(action.consent));
  }

  @Action(LocalActions.SetCanDisplayCookieBanner)
  setCanDisplayCookieBanner(ctx: StateContext<LocalStateModel>, action: LocalActions.SetCanDisplayCookieBanner) {
    ctx.patchState({
      canDisplayCookieBanner: action.canDisplayCookieBanner
    });
  }

  @Action(LocalActions.ResetCookiePolicy)
  resetCookiePolicy(ctx: StateContext<LocalStateModel>) {
    ctx.patchState({
      consent: undefined
    });
  }

  @Action(LocalActions.UpdateGoogleConsent)
  updateGoogleConsent(ctx: StateContext<LocalStateModel>, action: LocalActions.UpdateGoogleConsent) {
    this.gtagService.updateConsent(action.consent);
  }

  @Action(LocalActions.SetLastContribution)
  setLastContribution(ctx: StateContext<LocalStateModel>, action: LocalActions.SetLastContribution) {
    ctx.patchState({
      lastContributionId: action.lastContributionId
    });
  }

}
