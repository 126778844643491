
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventUpdatesService {

  constructor(private http: HttpClient) {
  }

  createEventUpdate(model: any) {
    return this.http.post<any>(`${this.endpoint}`, model);
  }

  updateEventUpdate(id: number, model: any) {
    return this.http.put<any>(`${this.endpoint}/${id}`, model);
  }

  deleteEventUpdate(id: number) {
    return this.http.delete<any>(`${this.endpoint}/${id}`);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/event-updates`;
  }

}
