import { NgModule } from '@angular/core';
import { NoPreloading,  RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'auth', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'user', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'corporate', loadChildren: () => import('./corporate/corporate.module').then(m => m.CorporateModule) },
  { path: 'pages', loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule) },
  { path: '', loadChildren: () => import('./static/static.module').then(m => m.StaticModule) },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: NoPreloading,// PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
