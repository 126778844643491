
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BasicFilter, PagedResult } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CorporateEventService {
  constructor(private http: HttpClient) {
  }

  getEvents(filter: BasicFilter = null): Observable<PagedResult<any>> {
    return this.http.get<any>(`${this.endpoint}`, { params: { ...filter } });
  }

  getEvent(eventCode: string) {
    return this.http.get<any>(`${this.endpoint}/${eventCode}`);
  }

  createEvent(model: any) {
    return this.http.post<any>(`${this.endpoint}`, model);
  }

  updateEvent(eventCode: string, model: any) {
    return this.http.put<any>(`${this.endpoint}/${eventCode}`, model);
  }

  getEventContributions(eventCode: string, createdDate: string) {
    return this.http.get<any>(`${this.endpoint}/${eventCode}/contributions?createdDate=${createdDate}`);
  }

  getContributionStatistics(eventCode: string, period: string) {
    const queryParams = {
      period: period || ''
    };

    return this.http.get<any>(`${this.endpoint}/${eventCode}/contribution-statistics`, { params: queryParams });
  }

  getUserContributionStatistics(period: string) {
    const queryParams = {
      period: period
    };

    return this.http.get<any>(`${this.endpoint}/contribution-statistics`, { params: queryParams });
  }

  getUserPartnerStatistics(period: string) {
    const queryParams = {
      period: period
    };

    return this.http.get<any>(`${this.endpoint}/partner-statistics`, { params: queryParams });
  }

  createContributions(eventCode: string, contact: any) {
    return this.http.post<any>(`${this.endpoint}/${eventCode}/contributions`, contact);
  }

  resendNotification(eventCode: string, contributionId: number) {
    return this.http.post<any>(`${this.endpoint}/${eventCode}/contributions/${contributionId}/resend`, {});
  }

  getCorporateImageTemplates(): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}/image-templates`);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/corporate-events`;
  }
}
