import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { environment } from '../../environments/environment';
import { StaticDataState } from './static-data.state';
import { AuthState } from './auth.state';
import { LocalState } from './local.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([AuthState, StaticDataState, LocalState], {
      developmentMode: !environment.production
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      logger: console,
      collapsed: false,
      disabled: true //environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      keys: ['auth.tokens', 'auth.user', 'local'],
      afterDeserialize: (obj, key) => {
        if (key === 'local') {
          if (obj.consent?.createdDate != null) {
            obj.consent.createdDate = new Date(obj.consent.createdDate);
          }
        }
        return obj;
      }
    })
  ],
  exports: [],
  declarations: [],
  providers: [
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class StateModule { }
