
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY, Observable, throwError, timer } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry({
          count: 3,
          resetOnSuccess: false,
          delay: genericRetryStrategy({
            maxRetryAttempts: 3,
            scalingDuration: 1000,
            excludedStatusCodes: [401, 403],
            includedVerbs: ['GET'],
            currentVerb: request.method,
          })
        }),
        catchError((e: HttpErrorResponse) => this.errorHandler(e))
      );
  }

  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    switch (response.status) {
      case 401: {
        this.authService.logout();

        // If it is an AUTH page don't swallow the error and let the page process 401 for login failed
        if (this.router.url.toUpperCase().startsWith('/AUTH/') == false) {
          return EMPTY;
        }
        break;
      }
      case 403: {
        this.router.navigate(['/forbidden']);
        return EMPTY;
      }
      // default: {
      //   this.toastService.show(
      //     'Error',
      //     (message || 'The application has encountered an unknown error.'),
      //     'error');
      // }
    }

    return throwError(() => response);
  }

}

//Retry with exponential backoff
export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = [],
  includedVerbs = [],
  currentVerb = '',
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[],
  includedVerbs?: string[],
  currentVerb?: string,
} = {}) => (error: any, retryCount: number) => {

  // if maximum number of retries have been met
  // or response is a status code we don't wish to retry, throw error
  if (
    retryCount > maxRetryAttempts ||
    excludedStatusCodes.find(e => e === error.status) ||
    !includedVerbs.find(e => e === currentVerb)
  ) {
    return throwError(() => error);
  }

  const delay = Math.pow(2, retryCount - 1) * scalingDuration;

  console.log(`Attempt ${retryCount}: ${error.url} in ${delay}ms`);

  return timer(delay);
};
