import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';

import { AccountService, ValidationService } from '../../../core/services';
import { ToastService } from '../../../core/services/toast.service';
import { FISCAL_CODE_REGEXP, NAME_REGEXP, REG_NUMBER_REGEXP } from '../../validators';
import { Address, AddressType, AddressUpsert, City, UserProfile } from '../../../core/models';
import { AuthActions } from '../../../state';

@Component({
  selector: 'gs-address-modal',
  templateUrl: 'address-modal.component.html',
})
export class AddressModalComponent implements OnInit, OnDestroy {

  address: Address;
  user: UserProfile;

  title: string;
  cities: City[] = [];

  form: FormGroup = this.formBuilder.group({
    id: [null],
    type: [null],
    firstName: [null, [Validators.required, Validators.maxLength(50),
    Validators.pattern(NAME_REGEXP)]],
    lastName: [null, [Validators.required, Validators.maxLength(50),
    Validators.pattern(NAME_REGEXP)]],
    cityId: [null, [Validators.required]],
    street: [null, [Validators.maxLength(100)]],
    fiscalCode: [null, [Validators.required, Validators.maxLength(12), Validators.pattern(FISCAL_CODE_REGEXP)]],
    company: [null, [Validators.required, Validators.maxLength(100)]],
    registrationNumber: [null, [Validators.required, Validators.maxLength(15), Validators.pattern(REG_NUMBER_REGEXP)]],
    isDefault: [false, [Validators.required]],
  });

  private subscriptions: Subscription[] = [];

  get controls() {
    return this.form.controls;
  }

  get isCompanyAddress(): boolean {
    return this.address?.type === AddressType.Company;
  }

  constructor(
    private bsModalRef: NgbActiveModal,
    private toastService: ToastService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private accountService: AccountService,
    private store: Store,
    private formBuilder: FormBuilder,
    public vs: ValidationService
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select<UserProfile>(state => state.auth.user)
        .pipe(filter(x => !!x))
        .subscribe(user => {
          this.user = user;
        }),

      this.store.select<City[]>(state => state.staticData.cities)
        .pipe(filter(x => !!x))
        .subscribe((data: City[]) => {
          this.cities = data;
        }),

      // this.controls.fiscalCode.valueChanges
      //   .pipe(
      //     debounceTime(500),
      //     distinctUntilChanged(),
      //     filter(x => !!x && this.controls.fiscalCode.valid)
      //   )
      //   .subscribe(x => {
      //     this.getCompanyDetails(x);
      //   }),
    );

    if (this.isCompanyAddress) {
      this.controls.fiscalCode.enable();
      this.controls.registrationNumber.enable();
      this.controls.company.enable();
      this.controls.street.enable();

      this.controls.firstName.disable();
      this.controls.lastName.disable();
    } else {
      this.controls.fiscalCode.disable();
      this.controls.registrationNumber.disable();
      this.controls.company.disable();
      this.controls.street.disable();

      this.controls.firstName.enable();
      this.controls.lastName.enable();
    }

    this.form.patchValue({
      ...this.address,
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Dismisses the modal with an optional `reason` value: ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK, and custom reasons. Used for negative actions such as clicking the "Cancel" button.
   *
   * @param {string} [reason=null]
   * @memberof AddressModalComponent
   */
  dismiss(reason: string = null) {
    this.bsModalRef.dismiss(reason);
  }

  /**
   * Closes the modal with an optional `result` value. Used for positive actions such as clicking a "Save" button.
   *
   * @param {string} [result=null]
   * @memberof AddressModalComponent
   */
  close(result: Address = null) {
    this.bsModalRef.close(result);
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    const model = {
      ...this.address,
      ...this.form.value,
    };

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.accountService.upsertAddress(this.user.code, model as AddressUpsert)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(() => e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe((address: Address) => {
          this.toastService.show('Succes', 'Modificarile au fost salvate cu succes.', 'success');

          this.store.dispatch(new AuthActions.SetUserAddress(address));

          this.form.reset();
          this.close(model as Address);
        })
    );

  }

  onFiscalCodeSearch() {
    if (!this.controls.fiscalCode.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    this.getCompanyDetails(this.controls.fiscalCode.value);
  }

  private getCompanyDetails(fiscalCode: string) {
    if (!fiscalCode) {
      return;
    }

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.accountService.getCompanyDetails(fiscalCode)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe(data => {
          if (data) {
            this.form.patchValue({
              ...data
            })
          }
        })
    );
  }
}
