
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient) {
  }

  downloadInvoice(code: string, fileName: string): Observable<never> {
    return this.http.get(`${this.endpoint}/${code}/pdf`, {
      headers: {
        Accept: 'image/*,application/pdf,*/*'
      },
      responseType: 'blob'
    }).pipe(
      mergeMap((data: Blob) => {
        this.downloadBlob(data, fileName);

        return EMPTY;
      })
    );
  }

  /**
   * Generic tigger saveAs browser modal
   *
   * @protected
   * @param {string} url
   * @memberof BaseService
   */
  downloadBlob(blob: Blob, fileName: string) {
    // const blob = new Blob([data], { type: type });
    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = objectUrl;
    link.download = fileName;
    link.click();
    // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    // Remove link
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(objectUrl);
      link.remove();
    }, 100);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/invoices`;
  }
}
