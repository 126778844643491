import { ApplicationRole, UserProfile } from "../models";

export function isStandardUser(user: UserProfile): boolean {
  return user?.roles?.includes(ApplicationRole.Member) ?? false;
}

export function isCorporateUser(user: UserProfile): boolean {
  return user?.roles?.includes(ApplicationRole.Corporate) ?? false;
}

export function isAdministratorUser(user: UserProfile): boolean {
  return user?.roles?.includes(ApplicationRole.Admin) ?? false;
}
