import { Directive, DoCheck, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isBrowserIE } from '../../core/helpers';

/**
 * IE validation fix
 *
 * @export
 * @class ValidateOnChangeDirective
 * @implements {DoCheck}
 */
@Directive({
  selector: '[gsValidateOnChange]'
})
export class ValidateOnChangeDirective implements DoCheck {
  private oldValue;
  private isDirty = false;

  constructor(private formControl: NgControl) {
  }

  @HostListener('input', ['$event'])
  onInputChange($event) {
    const text = $event.target.value;
    if (isBrowserIE()) {
      if (this.isDirty) {
        return;
      }
      if (text == '' && (this.oldValue == '' || this.oldValue == null)) {
        this.formControl.control.markAsPristine();
        return;
      }
      if (text != '' || (text == '' && this.oldValue != '')) {
        this.formControl.control.markAsDirty();
        this.isDirty = true;
      }
    }
  }

  ngDoCheck() {
    this.oldValue = this.formControl.control.value;
  }

}
