export interface BasicFilter {
  size: number;
  number: number;
  sortColumn: string;
  sortDirection: string;
}

export interface DateRangeFilter extends BasicFilter {
  fromDate?: Date;
  toDate?: Date;
}
