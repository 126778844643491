<footer class="footer pb-2 pb-md-5" [ngClass]="containerClass">
  <div class="container-fluid pb-1 pb-md-0 px-md-5">
    <div class="dark-mode bg-dark rounded-5 position-relative overflow-hidden w-100 py-5 px-3 px-sm-4 px-xl-5 mx-auto"
      style="max-width: 1660px;">
      <div class="position-absolute top-50 start-50 translate-middle" style="width: 1664px;"><img
          src="assets/images/resource/footer-wave.png" alt="Wave"></div>
      <div class="container position-relative zindex-2 pt-md-3 pt-lg-4 pt-xl-5 pb-2">
        <div class="row pb-2">
          <div class="col-lg-4 col-xxl-3 pb-2 pb-lg-0 mb-4 mb-lg-0">
            <a class="navbar-brand text-light py-0 me-0 pb-1 mb-3" [routerLink]="['/']">
              <span class="text-primary flex-shrink-0 me-2">
                <svg-icon src="assets/images/icons/giftshare-logo-light.svg" [svgStyle]="{'height.px': 32}"></svg-icon>
              </span>
            </a>

            <h5 class="mb-4"><span class="text-primary fw-bold">Cadoul perfect</span> de fiecare data!</h5>

            <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
              <div class="input-group input-group-sm rounded-pill">
                <input formControlName="email" [ngClass]="{'is-invalid': vs.hasErrors(form, 'email')}"
                  class="form-control" type="text" placeholder="Email">
                <button class="btn btn-primary rounded-pill" type="submit">Abonare</button>
              </div>
              <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'email')">{{vs.getError(form, 'email')}}
              </div>
            </form>
          </div>
          <div class="col-sm-3 col-lg-2 offset-xxl-1 mb-4 mb-sm-0">
            <h6 class="fw-bold">Solutii</h6>
            <ul class="nav flex-column fs-sm">
              <li class="nav-item"><a [routerLink]="['/card-cadou-giftshare']"
                  class="nav-link fw-normal px-0 py-1">Cardul Cadou GiftShare</a>
              </li>
              <li class="nav-item"><a [routerLink]="['/card-cadou-corporate']"
                  class="nav-link fw-normal px-0 py-1">GiftShare pentru Companii</a>
              </li>
              <li class="nav-item"><a [routerLink]="['/card-cadou-concursuri']"
                  class="nav-link fw-normal px-0 py-1">GiftShare pentru Concursuri</a>
              </li>
              <li class="nav-item"><a [routerLink]="['/caritate']" class="nav-link fw-normal px-0 py-1">Caritate</a>
              </li>
            </ul>
          </div>
          <div class="col-sm-3 col-lg-2 mb-4 mb-sm-0">
            <h6 class="fw-bold">Companie</h6>
            <ul class="nav flex-column fs-sm">
              <li class="nav-item"><a [routerLink]="['/despre-noi']" class="nav-link fw-normal px-0 py-1">Despre noi</a>
              </li>
              <li class="nav-item"><a [routerLink]="['/social']" class="nav-link fw-normal px-0 py-1">Social media</a>
              </li>
              <li class="nav-item"><a [href]="blogUrl" class="nav-link fw-normal px-0 py-1">Blog</a></li>
              <li class="nav-item"><a [routerLink]="['/parteneri']" class="nav-link fw-normal px-0 py-1">Parteneri</a>
              </li>
            </ul>
          </div>
          <div class="col-sm-3 col-lg-2 mb-4 mb-sm-0">
            <h6 class="fw-bold">Suport</h6>
            <ul class="nav flex-column fs-sm">
              <li class="nav-item"><a [routerLink]="['/cum-functioneaza']" class="nav-link fw-normal px-0 py-1">Cum
                  functioneaza</a></li>
              <li class="nav-item"><a [routerLink]="['/intrebari-frecvente']"
                  class="nav-link fw-normal px-0 py-1">Intrebari frecvente</a></li>
              <li class="nav-item"><a (click)="onReportBugClick()" class="nav-link fw-normal px-0 py-1" role="button">Raporteaza o
                  eroare</a></li>
              <li class="nav-item"><a [routerLink]="['/contact']" class="nav-link fw-normal px-0 py-1">Contact</a></li>
            </ul>
          </div>
          <div class="col-sm-3 col-lg-2">
            <h6 class="fw-bold">Legal</h6>
            <ul class="nav flex-column fs-sm">
              <li class="nav-item"><a [routerLink]="['/politica-de-confidentialitate']"
                  class="nav-link fw-normal px-0 py-1">Politica de confidentialitate</a></li>
              <li class="nav-item"><a [routerLink]="['/termeni-si-conditii']"
                  class="nav-link fw-normal px-0 py-1">Termeni si conditii</a></li>
              <li class="nav-item"><a [routerLink]="['/cookies']" class="nav-link fw-normal px-0 py-1">Cookies</a></li>
              <li class="nav-item"><a (click)="onCookieConsentClick()" class="nav-link fw-normal px-0 py-1"
                  role="button">Consimtamant cookies</a></li>
              <li class="nav-item w-50 w-sm-100">
                <a href="https://anpc.ro/ce-este-sal/" target="_blank">
                  <img src="/assets/images/resource/anpc-sal.png" class="px-0 py-1" alt="ANPC SAL" />
                </a>
              </li>
              <li class="nav-item w-50 w-sm-100">
                <a href="https://ec.europa.eu/consumers/odr/" target="_blank">
                  <img src="/assets/images/resource/ce-sol.png" class="px-0 py-1" alt="ANPC SOL" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="d-sm-flex align-items-center justify-content-between pt-4 pt-md-5 mt-2 mt-md-0 mt-lg-2 mt-xl-4">
          <div class="d-flex justify-content-center order-sm-2 me-md-n2">
            <a href="https://www.facebook.com/GiftShare.ro/" target="_blank"
              class="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle mx-2"><i class="ai-facebook"></i></a>
            <a href="https://www.instagram.com/giftshare.ro" target="_blank"
              class="btn btn-icon btn-sm btn-secondary btn-instagram rounded-circle mx-2"><i
                class="ai-instagram"></i></a>
            <a href="https://www.youtube.com/channel/UCbX0v_CbKropGK8MTLAGp5A" target="_blank"
              class="btn btn-icon btn-sm btn-secondary btn-youtube rounded-circle mx-2"><i class="ai-youtube"></i></a>
            <a href="https://twitter.com/giftsharero" target="_blank"
              class="btn btn-icon btn-sm btn-secondary btn-twitter rounded-circle mx-2"><i class="ai-twitter"></i></a>
            <a href="https://www.linkedin.com/company/giftshare-ro/" target="_blank"
              class="btn btn-icon btn-sm btn-secondary btn-linkedin rounded-circle mx-2"><i class="ai-linkedin"></i></a>
          </div>
          <p class="fs-sm order-sm-1 text-center text-sm-start pt-4 pt-sm-0 mb-0 me-4"><span class="opacity-70">&copy;
              2017-{{ currentDate | date: 'y'}}
              <a [routerLink]="['/']">GiftShare</a> - IT INNOVATION CORP, CUI: 37773927, REG: J40/9405/2017</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
