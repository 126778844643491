import { Injectable } from '@angular/core';

import {
  State, Action, Selector, StateContext,
  NgxsOnInit, NgxsAfterBootstrap, NgxsSimpleChange, NgxsOnChanges
} from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { UserTokens, UserProfile, Notification, Address } from '../core/models';
import { AccountService } from '../core/services/account.service';
import { OrderService } from '../core/services/order.service';
import { NotificationService } from '../core/services/notification.service';
import { formatDate } from '@angular/common';
import { isAdministratorUser, isCorporateUser, isStandardUser } from '../core/helpers';
import { EMPTY } from 'rxjs';

/* Actions */
export namespace AuthActions {

  const ActionPrefix: string = '[Auth] ';

  export class SetUser {
    static readonly type = `${ActionPrefix}SetUser`;
    constructor(public user: any) { }
  }

  export class SetTawkToUser {
    static readonly type = `${ActionPrefix}SetTawkToUser`;
    constructor(public user: any) { }
  }

  export class SetTokens {
    static readonly type = `${ActionPrefix}SetTokens`;
    constructor(public tokens: UserTokens) { }
  }

  export class SetUserWalletAmount {
    static readonly type = `${ActionPrefix}SetUserWalletAmount`;
    constructor(public walletAmount: number) { }
  }

  export class SetUserAddress {
    static readonly type = `${ActionPrefix}SetUserAddress`;
    constructor(public address: Address) { }
  }

  export class SetCartCount {
    static readonly type = `${ActionPrefix}SetCartCount`;
    constructor(public cartCount: number) { }
  }

  export class GetCartCount {
    static readonly type = `${ActionPrefix}GetCartCount`;
    constructor() { }
  }

  export class AddNotification {
    static readonly type = `${ActionPrefix}AddNotification`;
    constructor(public notification: Notification) { }
  }

  export class SetNotifications {
    static readonly type = `${ActionPrefix}SetNotifications`;
    constructor(public notifications: Notification[]) { }
  }

  export class GetNotifications {
    static readonly type = `${ActionPrefix}GetNotifications`;
    constructor() { }
  }

  export class StartNotifications {
    static readonly type = `${ActionPrefix}StartNotifications`;
    constructor() { }
  }

  export class StopNotifications {
    static readonly type = `${ActionPrefix}StopNotifications`;
    constructor() { }
  }

  export class GetCurrentUser {
    static readonly type = `${ActionPrefix}GetCurrentUser`;
    constructor() { }
  }

  export class Logout {
    static readonly type = `${ActionPrefix}Logout`;
    constructor() { }
  }
}

/* State interface */
export interface AuthStateModel {
  user: UserProfile;
  tokens: UserTokens;
  cartCount: number;
  notifications: Notification[];
}

/* Default state */
const defaults: AuthStateModel = {
  user: undefined,
  tokens: undefined,
  cartCount: 0,
  notifications: []
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: defaults
})
@Injectable()
export class AuthState implements NgxsOnInit, NgxsAfterBootstrap, NgxsOnChanges {

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.tokens?.token;
  }

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.tokens?.token;
  }

  @Selector()
  static user(state: AuthStateModel): UserProfile | null {
    return state.user;
  }

  @Selector()
  static isStandardUser(state: AuthStateModel): boolean {
    return isStandardUser(state.user);
  }

  @Selector()
  static isCorporateUser(state: AuthStateModel): boolean {
    return isCorporateUser(state.user);
  }

  @Selector()
  static isAdministratorUser(state: AuthStateModel): boolean {
    return isAdministratorUser(state.user);
  }

  constructor(
    private orderService: OrderService,
    private accountService: AccountService,
    private notificationService: NotificationService,
  ) {
  }

  //Called before ngxsOnInit() and whenever state changes.
  ngxsOnChanges(change: NgxsSimpleChange) {
    // console.log('prev state', change.previousValue);
    // console.log('next state', change.currentValue);
  }

  //Called once, after the first ngxsOnChanges() and before the APP_INITIALIZER token is resolved.
  ngxsOnInit(ctx?: StateContext<AuthStateModel>) {
    if (ctx.getState().user) {
      ctx.dispatch([
        new AuthActions.GetCurrentUser(),
        new AuthActions.GetCartCount(),
        new AuthActions.GetNotifications(),
        new AuthActions.StartNotifications(),
      ]);
    }
  }

  // //Called once, after the root view and all its children have been rendered.
  ngxsAfterBootstrap(ctx?: StateContext<AuthStateModel>): void {
  }

  @Action(AuthActions.SetUser)
  setUser(ctx: StateContext<AuthStateModel>, action: AuthActions.SetUser) {
    // const state = ctx.getState();
    // ctx.setState({
    //     ...state,
    //     count: state.count + action.increment
    //   });

    //Alt
    // ctx.setState(state => ({
    //     ...state,
    //     profile: action.profile
    // }));

    //Shortcut
    ctx.patchState({
      user: action.user
    });
    setTimeout(() => {
      ctx.dispatch(new AuthActions.SetTawkToUser(action.user));
    }, 5000);
  }

  @Action(AuthActions.SetTawkToUser)
  setTawkToUser(ctx: StateContext<AuthStateModel>, action: AuthActions.SetTawkToUser) {
    if (window['Tawk_API'].setAttributes) {
      window['Tawk_API'].setAttributes({
        'userId': action.user?.id,
        'name': action.user?.name,
        'email': action.user?.email,
        'phone': action.user?.phone,
        'city': action.user?.cityNameFormatted,
        'registrationSource': action.user?.registrationSource,
        'createdDate': action.user?.createdDate ? formatDate(action.user?.createdDate, 'dd/MM/yyyy HH:mm', 'en') : null,
        'role': action.user?.roles[0],
      }, function (error) {
        if (error) {
          console.error('Error occurred while logging in to Tawk:', error);
        } else {
          console.log('User logged in to Tawk successfully');
        }
      });
    }
  }

  @Action(AuthActions.SetTokens)
  setTokens(ctx: StateContext<AuthStateModel>, action: AuthActions.SetTokens) {
    ctx.patchState({
      tokens: action.tokens
    });
  }

  @Action(AuthActions.SetUserWalletAmount)
  setUserWalletAmount(ctx: StateContext<AuthStateModel>, action: AuthActions.SetUserWalletAmount) {
    ctx.patchState({
      user: { ...ctx.getState().user, walletAmount: action.walletAmount }
    });
  }

  @Action(AuthActions.SetUserAddress)
  setUserAddress(ctx: StateContext<AuthStateModel>, action: AuthActions.SetUserAddress) {
    const user = ctx.getState().user;
    const addressIndex = user.addresses.findIndex(x => x.type === action.address.type);

    //const address = user.addresses.map(x => x.type === action.address.type ? action.address : x);
    ctx.patchState({
      user: {
        ...user,
        // Upsert
        addresses: addressIndex > -1
          ? user.addresses.map((x, index) => index === addressIndex ? action.address : { ...x, isDefault: !action.address.isDefault })
          : [...user.addresses.map(x => ({ ...x, isDefault: !action.address.isDefault })), action.address]
      }
    });
  }

  @Action(AuthActions.SetCartCount)
  setCartCount(ctx: StateContext<AuthStateModel>, action: AuthActions.SetCartCount) {
    ctx.patchState({
      cartCount: action.cartCount
    });
  }

  @Action(AuthActions.GetCartCount)
  getCartCount(ctx: StateContext<AuthStateModel>) {
    return this.orderService.getCartItems()
      .pipe(
        tap(result => {
          ctx.dispatch(new AuthActions.SetCartCount(result.length));
        })
      );
  }

  @Action(AuthActions.AddNotification)
  addNotification(ctx: StateContext<AuthStateModel>, action: AuthActions.AddNotification) {
    ctx.patchState({
      notifications: [
        action.notification,
        ...ctx.getState().notifications
      ]
    });
  }

  @Action(AuthActions.SetNotifications)
  setNotifications(ctx: StateContext<AuthStateModel>, action: AuthActions.SetNotifications) {
    ctx.patchState({
      notifications: action.notifications
    });
  }

  @Action(AuthActions.GetNotifications)
  getNotifications(ctx: StateContext<AuthStateModel>) {
    return this.notificationService.getNotifications()
      .pipe(
        tap(result => {
          ctx.dispatch(new AuthActions.SetNotifications(result));
        })
      );
  }

  @Action(AuthActions.StartNotifications)
  startNotifications(ctx: StateContext<AuthStateModel>) {
    if (this.notificationService.isConnected) {
      return EMPTY;
    }

    return this.notificationService.startConnection()
      .pipe(
        tap(result => {
          ctx.dispatch(new AuthActions.AddNotification(result));
        })
      );
  }

  @Action(AuthActions.StopNotifications)
  stopNotifications(ctx: StateContext<AuthStateModel>) {
    return this.notificationService.stopConnection();
  }

  @Action(AuthActions.GetCurrentUser)
  getCurrentUser(ctx: StateContext<AuthStateModel>) {
    return this.accountService.getCurrentUser()
      .pipe(
        tap(result => {
          if (result) {
            ctx.dispatch(new AuthActions.SetUser(result));
          }
        })
      );
  }

  @Action(AuthActions.Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.setState(defaults);
  }

  // public get userImageUrl(): string {
  //   if (this.user) {
  //     return this.user.user.image ? environment.contentUrl + '/user-images/' + this.user.user.image
  //       : environment.userDefaultImageUrl;
  //   }
  //   return null;
  // }
}
