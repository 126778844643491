
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BlogService {

  constructor(private http: HttpClient) {
  }

  getRecentPosts() {
    return this.http.get<any>(environment.blogUrl + '/wp-json/wp/v2/posts?per_page=3&orderby=date&order=desc&_embed');
  }
}
