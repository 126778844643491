/**
 * Determine if user-agent is Internet Explorer
 *
 * @static
 * @returns {boolean}
 */
export function isBrowserIE(): boolean {
  const ua = navigator.userAgent;
  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}

/**
 * Determine if user-agent is Facebook in-app browser
 *
 * @static
 * @returns {boolean}
 */
export function isFacebookInApp(): boolean {
  const ua = navigator.userAgent || navigator.vendor;
  return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}
