<div class="container fixed-bottom mb-3">

  <div class="card bg-secondary border-info">
    <div class="card-header py-3">

      <!-- Nav tabs-->
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId"
        class="nav-justified card-header-tabs flex-column flex-sm-row text-nowrap mb-n2">
        <li [ngbNavItem]="1">
          <a ngbNavLink class="justify-content-center">Consimtamant</a>
          <ng-template ngbNavContent>
            <ng-scrollbar class="h-56 overflow-y-auto">
              <h5>Acest site utilizeaza cookie-uri</h5>
              <p class="mb-0">Folosim cookie-uri pentru a personaliza continutul si anunturile, pentru a oferi functii
                de
                retele sociale si pentru a analiza traficul. De asemenea, le oferim partenerilor de retele sociale, de
                publicitate si de analize informatii cu privire la modul in care folositi site-ul nostru. Acestia le pot
                combina cu alte informatii oferite de dvs. sau culese in urma folosirii serviciilor lor. Pentru a afla
                mai
                multe, cititi <a [routerLink]="['/cookies']">Politica cookies</a> si
                <a [routerLink]="['/politica-de-confidentialitate']">Politica de confidentialitate</a>.
              </p>
            </ng-scrollbar>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink class="justify-content-center">Detalii</a>
          <ng-template ngbNavContent>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-0" novalidate autocomplete="off">

              <ng-scrollbar class="h-80 overflow-y-auto">
                <div ngbAccordion [closeOthers]="true">
                  <div ngbAccordionItem [collapsed]="false" class="bg-light">
                    <div ngbAccordionHeader>
                      <button ngbAccordionButton>
                        Necesare
                      </button>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody class="d-flex justify-content-between flex-column flex-sm-row">
                        <ng-template>
                          <div class="fs-sm">Cookie-urile necesare ajuta la a face un site utilizabil prin activarea
                            functiilor de baza,
                            precum
                            navigarea in pagina si accesul la zonele securizate de pe site. Site-ul nu poate functiona
                            corespunzator fara aceste cookie-uri.</div>
                          <div class="form-check form-switch ms-0 ms-sm-4 mt-4 mt-sm-0">
                            <input formControlName="isNecessaryAllowed"
                              [ngClass]="{'is-invalid': vs.hasErrors(form, 'isNecessaryAllowed')}" type="checkbox"
                              class="form-check-input" id="isNecessaryAllowed">
                            <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'isNecessaryAllowed')">
                              {{vs.getError(form, 'isNecessaryAllowed')}}</div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div ngbAccordionItem class="bg-light">
                    <div ngbAccordionHeader>
                      <button ngbAccordionButton>
                        Preferinte
                      </button>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody class="d-flex justify-content-between flex-column flex-sm-row"><ng-template>
                          <div class="fs-sm">Cookie-urile de preferinta permit unui site sa isi aminteasca informatii
                            care
                            se modifica dupa modul
                            in care se comporta sau arata site-ul, precum limba dvs. preferata sau regiunea in care va
                            aflati.</div>

                          <div class="form-check form-switch ms-0 ms-sm-4 mt-4 mt-sm-0">
                            <input formControlName="isPreferencesAllowed"
                              [ngClass]="{'is-invalid': vs.hasErrors(form, 'isPreferencesAllowed')}" type="checkbox"
                              class="form-check-input" id="isPreferencesAllowed">
                            <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'isPreferencesAllowed')">
                              {{vs.getError(form, 'isPreferencesAllowed')}}</div>
                          </div>

                        </ng-template></div>
                    </div>
                  </div>
                  <div ngbAccordionItem class="bg-light">
                    <div ngbAccordionHeader>
                      <button ngbAccordionButton>
                        Statistici
                      </button>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody class="d-flex justify-content-between flex-column flex-sm-row"><ng-template>
                          <div class="fs-sm">Cookie-urile de statistica ii ajuta pe proprietarii unui site sa inteleaga
                            modul in care
                            vizitatorii
                            interactioneaza cu site-urile prin colectarea si raportarea informatiilor in mod anonim.
                          </div>

                          <div class="form-check form-switch ms-0 ms-sm-4 mt-4 mt-sm-0">
                            <input formControlName="isStatisticsAllowed"
                              [ngClass]="{'is-invalid': vs.hasErrors(form, 'isStatisticsAllowed')}" type="checkbox"
                              class="form-check-input" id="isStatisticsAllowed">
                            <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'isStatisticsAllowed')">
                              {{vs.getError(form, 'isStatisticsAllowed')}}</div>
                          </div>

                        </ng-template></div>
                    </div>
                  </div>
                  <div ngbAccordionItem class="bg-light">
                    <div ngbAccordionHeader>
                      <button ngbAccordionButton>
                        Marketing
                      </button>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody class="d-flex justify-content-between flex-column flex-sm-row"><ng-template>
                          <div class="fs-sm">Cookie-urile de marketing sunt utilizate pentru a-i urmari pe utilizatori
                            de
                            la
                            un site la altul.
                            Intentia este de a afisa anunturi relevante si antrenante pentru utilizatorii individuali,
                            asadar ele sunt mai valoroase pentru agentiile de puiblicitate si partile terte care se
                            ocupa
                            de
                            publicitate.</div>
                          <div class="form-check form-switch ms-0 ms-sm-4 mt-4 mt-sm-0">
                            <input formControlName="isMarketingAllowed"
                              [ngClass]="{'is-invalid': vs.hasErrors(form, 'isMarketingAllowed')}" type="checkbox"
                              class="form-check-input" id="isMarketingAllowed">
                            <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'isMarketingAllowed')">
                              {{vs.getError(form, 'isMarketingAllowed')}}</div>
                          </div>

                        </ng-template></div>
                    </div>
                  </div>
                  <div ngbAccordionItem class="bg-light">
                    <div ngbAccordionHeader>
                      <button ngbAccordionButton>
                        Neclasificate
                      </button>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody class="d-flex justify-content-between flex-column flex-sm-row"><ng-template>
                          <div class="fs-sm">Cookie-urile neclasificate sunt cookie-uri in curs de clasificare, impreuna
                            cu
                            furnizorii de cookie-uri individuale.</div>
                          <div class="form-check form-switch ms-0 ms-sm-4 mt-4 mt-sm-0">
                            <input formControlName="isUnclassifiedAllowed"
                              [ngClass]="{'is-invalid': vs.hasErrors(form, 'isUnclassifiedAllowed')}" type="checkbox"
                              class="form-check-input" id="isUnclassifiedAllowed">
                            <div class="invalid-feedback" *ngIf="vs.hasErrors(form, 'isUnclassifiedAllowed')">
                              {{vs.getError(form, 'isUnclassifiedAllowed')}}</div>
                          </div>
                        </ng-template></div>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>

            </form>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink class="justify-content-center">Despre</a>
          <ng-template ngbNavContent>
            <ng-scrollbar class="h-56 overflow-y-auto">
              <p>Cookie-urile sunt mici fisiere de text ce pot fi utilizate de catre site-urile web pentru a
                face utilizarea lor mai eficienta.</p>
              <p>Legea stipuleaza ca putem stoca cookie-uri pe dispozitivul dvs., in cazul in care ele
                sunt strict necesare pentru operarea acestui site. Pentru toate celelalte tipuri de cookie-uri avem
                nevoie
                de permisiunea dvs.</p>
              <p>Acest site utilizeaza diferite tipuri de cookie-uri. Unele cookie-uri sunt plasate de
                catre servicii parti terte care apar pe paginile noastre.</p>
              <p>Puteti, in orice moment, sa modificati sau sa va retrageti acordul din Declaratia privind
                modulele cookie de pe website-ul nostru.</p>
              <p>Aflati mai multe despre cine suntem, cum ne puteti contacta si cum procesam datele
                personale in Politica noastra de confidentialitate.</p>
              <p class="mb-0">Cand ne contactati in legatura cu consimtamantul dvs., va rugam sa precizati ID-ul si data
                consimtamantului dat.</p>
            </ng-scrollbar>
          </ng-template>
        </li>
      </ul>
    </div>
    <div class="card-body py-3">
      <!-- Tab content-->
      <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="card-footer d-flex flex-wrap justify-content-end align-items-center py-3">
      <button (click)="onAllowAllCookiesClick()"
        class="btn btn-lg btn-primary w-100 w-sm-auto ms-sm-3 mb-3 mb-sm-0 order-sm-2" type="button">Permite
        toate</button>
      <button (click)="onCustomCookiesClick()" class="btn btn-lg btn-primary w-100 w-sm-auto"
        type="button">{{activeTabId ==
        2 ? 'Permite selectia' : 'Personalizeaza'}}</button>
    </div>
  </div>

</div>
