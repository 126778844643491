import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';

import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { SentryErrorHandler } from './core/services/sentry-error-handler.service';
import { SharedModule } from './shared';
import { StateModule } from './state/state.module';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

function configurationFactory(httpHandler: HttpBackend): () => Observable<any> {
  const httpClient = new HttpClient(httpHandler);
  return () => httpClient.get('/environment.json', {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate, proxy-revalidate, max-age=0',
      'Pragma': 'no-cache',
      'Expires': '0',
    }
  }).pipe(
    first(),
    tap((dynamicConfiguration) => {
      mergeConfiguration(environment, dynamicConfiguration);
      SentryErrorHandler.init();
    })
  );
}

/*
  Deploy from VS2022 (build-staging, build-demo, build-accept)
  - Build with environment.(env).ts configuration
  - Only non-null environment.json items are merged, and since everything is null they are not applied

  Deploy from Pipelines (build-generic)
  - Build with environment.generic.ts configuration, set variables in environment.json
  - Only non-null environment.json items are merged over environment.generic.ts
*/
function mergeConfiguration(target, source) {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (isObject(target[key])) {
        mergeConfiguration(target[key], source[key]);
      } else {
        if (source[key] !== null) {
          target[key] = source[key];
        }
      }
    }
  }
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    SocialLoginModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule.forRoot({
      loaderId: 'router-loader',
      showForeground: false
    }),
    NgxUiLoaderHttpModule.forRoot({
      loaderId: 'http-loader',
      showForeground: false
    }),
    StateModule
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configurationFactory,
      deps: [HttpBackend],
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: () => {
        return {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                environment.googleClientId
              )
            }
          ]
        } as SocialAuthServiceConfig;
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeRo, 'ro');
  }
}

