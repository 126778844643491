import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthState } from '../../state/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private store: Store,
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes(environment.apiUrl)) {
      let headers: HttpHeaders = request.headers;
        // .set('Cache-Control', 'no-cache')
        // .set('Pragma', 'no-cache')
        // .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        // .set('If-Modified-Since', '0');

      // if (headers.has('Content-Type') == false &&
      //   !request.url.includes(environment.imageUploadUrl) &&
      //   !request.url.includes('/api/v1/invites/upload')) {
      //   headers = headers.set('Content-Type', 'application/json');
      // }

      if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
        headers = headers.set('Authorization', `Bearer ${this.store.selectSnapshot(AuthState.token)}`);
      }

      if (headers.keys().length > request.headers.keys().length) {
        request = request.clone({
          headers: headers
        });
      }

    }

    return next.handle(request);
  }
}
