import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { CommonService, ValidationService } from '../../../core/services';
import { ToastService } from '../../../core/services/toast.service';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { GiftShareValidators } from '../../validators';
import { UserProfile } from '../../../core/models';

@Component({
  selector: 'gs-feedback-modal',
  templateUrl: 'feedback-modal.component.html',
})
export class FeedbackModalComponent implements OnInit, OnDestroy {
  @Select(state => state.local.theme) theme$: Observable<ReCaptchaV2.Theme>;

  title: string = "Raporteaza o eroare";
  message: string;
  form: FormGroup;

  private subscriptions: Subscription[] = [];

  get controls() {
    return this.form.controls;
  }

  get recaptchaSiteKey() {
    return environment.googleRecaptcha.siteKey;
  }

  constructor(
    private bsModalRef: NgbActiveModal,
    private toastService: ToastService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private commonService: CommonService,
    private store: Store,
    private formBuilder: FormBuilder,
    public vs: ValidationService
  ) {

    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.maxLength(100)]],
      email: [null, [Validators.required, GiftShareValidators.email, Validators.maxLength(256)]],
      message: [null, [Validators.required, Validators.maxLength(10000)]],
      screenshot: [null],
      captcha: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select<UserProfile>(state => state.auth.user)
        .pipe(filter(x => !!x))
        .subscribe(user => {
          this.form.patchValue({
            name: user.name,
            email: user.email,
          });
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  close() {
    this.bsModalRef.close();
  }

  // This will be invoked only when setting errorMode to handled
  // https://github.com/DethAriel/ng-recaptcha?tab=readme-ov-file#handling-errors
  onCaptchaResolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onCaptchaError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered:`, errorDetails);
  }

  onSubmit() {
    if (!this.form.valid) {
      this.vs.triggerValidation(this.form);
      return;
    }

    const model = {
      ...this.form.value
    };

    this.ngxUiLoaderService.start();
    this.subscriptions.push(
      this.commonService.sendBugReport(model)
        .pipe(
          catchError(e => {
            this.toastService.show('Eroare', e.error?.detail || e.error?.title || 'A aparut o problema neasteptata. Echipa noastra lucreaza pentru a o remedia.', 'error');
            return throwError(e);
          }),
          finalize(() => {
            this.ngxUiLoaderService.stop();
          })
        )
        .subscribe(_ => {
          this.form.reset();
          this.toastService.show(
            'Succes',
            'Mesajul tau a fost trimis cu succes.',
            'success'
          );
          this.close();
        })
    );
  }
}
