const regexDateTimeISO: RegExp =
  new RegExp('^(\\d{4})-(\\d{2})-(\\d{2})T(\\d{2}):(\\d{2}):(\\d{2}(?:\\.\\d*)?)(?:Z|(\\+|-)([\\d|:]*))?$', 'mi');

const regexDateISO: RegExp =
  new RegExp('^(\\d{4})-(\\d{2})-(\\d{2})$', 'mi');

/**
* Generic method to convert all string fields that match ISO8601 pattern to Date object
*
* @protected
* @template T
* @param {T} model
* @returns {T}
*/
export function mapDates<T>(model: T): T {
  if (!model) {
    return model;
  }

  // if (typeof model !== 'object' && !Array.isArray(model)) {
  //   return model;
  // }

  Object.keys(model).forEach(key => {
    if (isIsoDateString(model[key])) {
      model[key] = new Date(model[key]);
    } else if (model[key] && Array.isArray(model[key])) {
      model[key].forEach(x => mapDates(x));
    } else if (typeof model[key] === 'object') {
      mapDates(model[key]);
    }
  });
  return model;
}

function isIsoDateString(value: any): boolean {
  if (!value || typeof value !== 'string') {
    return false;
  }

  return regexDateTimeISO.test(value) || regexDateISO.test(value);
}

export function dateToString(date: Date): string {
  return date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : null;
}

export function stringToDate(date: string): Date {
  const [day, month, year] = date.split(/[\s/]+/, 3);
  return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
}

export function isValidDate(date: string) {
  // First check for the pattern
  if (!/^(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{4}$/.test(date)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = date.split(/[\s/]+/, 3);
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month <= 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

//Get dates between
export function getDates(startDate: Date, stopDate: Date) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, (1));
  }
  return dateArray;
}

export function addDays(input: Date, days: number) {
  const date = new Date(input);
  date.setDate(date.getDate() + days);
  return date;
}
