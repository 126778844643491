<div class="modal-header border-0">
  <h4 class="modal-title">{{title}}</h4>
  <button (click)="close()" class="btn-close" type="button" aria-label="Close"></button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-0" novalidate autocomplete="off">
  <div class="modal-body">
    <div class="row row-cols-1 g-4">
      <div class="col">
        <label class="form-label" for="code">Cod card cadou GiftShare</label>
        <input formControlName="code" [ngClass]="{'is-invalid': vs.hasErrors(form, 'code')}"
          [dropSpecialCharacters]="false" mask="00000-00000" inputmode="numeric" class="form-control form-control-lg"
          type="text" placeholder="XXXXX-XXXXX" id="code" autofocus>
        <div *ngIf="vs.hasErrors(form, 'code')" class="invalid-feedback">{{vs.getError(form,
          'code')}}
        </div>
      </div>
      <div class="col">
        <div class="form-check">
          <input formControlName="hasAcceptedCouponTerms" [ngClass]="{'is-invalid': vs.hasErrors(form, 'hasAcceptedCouponTerms')}"
            class="form-check-input" type="checkbox" id="hasAcceptedCouponTerms">
          <label class="form-check-label text-dark fw-medium" for="hasAcceptedCouponTerms">Am citit si sunt de acord cu
            <a target="_blank" [routerLink]="['/termeni-si-conditii']">
              Termenii si conditiile
            </a>
          </label>
          <div *ngIf="vs.hasError(form, 'hasAcceptedCouponTerms', 'required')" class="invalid-feedback">Termenii si
            conditiile trebuie citite si acceptate.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button (click)="close()" class="btn btn-secondary w-100 w-sm-auto mb-3 mb-sm-0" type="button">Anuleaza</button>
    <button class="btn btn-primary w-100 w-sm-auto ms-sm-3" type="submit">Aplica</button>
  </div>
</form>
