import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserProfile } from '../models';

@Injectable()
export class RoleGuard  {
  constructor(
    private router: Router,
    private store: Store
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.store.selectSnapshot<UserProfile>(state => state.auth.user);
    if (user) {
      const allowRoles = route.data.allowRoles as string[];
      if (allowRoles && !allowRoles.some(x => user?.roles?.includes(x) ?? false)) {
        this.router.navigate(['/forbidden']);
        return false;
      }

      const denyRoles = route.data.denyRoles as string[];
      if (denyRoles && denyRoles.some(x => user?.roles?.includes(x) ?? false)) {
        this.router.navigate(['/forbidden']);
        return false;
      }
    }

    return true;
  }
}
