
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BasicFilter, PagedResult } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private http: HttpClient) {
  }

  createEvent(model: any) {
    return this.http.post<any>(`${this.endpoint}`, model);
  }

  updateEvent(code: string, model: any) {
    return this.http.put<any>(`${this.endpoint}/${code}`, model);
  }

  deleteEvent(code: string) {
    return this.http.delete<any>(`${this.endpoint}/${code}`);
  }

  getEvents(filter: BasicFilter = null): Observable<PagedResult<any>> {
    return this.http.get<any>(`${this.endpoint}/list`, { params: { ...filter } });
  }

  getEventInvitations(filter: BasicFilter = null): Observable<PagedResult<any>> {
    return this.http.get<any>(`${this.endpoint}/invitations`, { params: { ...filter } });
  }

  getEventByCode(code: string) {
    return this.http.get<any>(`${this.endpoint}/${code}`);
  }

  getPublicEventByCode(code: string) {
    return this.http.get<any>(`${this.endpoint}/${code}/public`);
  }

  getPublicEventDetailsByCode(code: string) {
    return this.http.get<any>(`${this.endpoint}/${code}/details`);
  }

  searchEvents(keyword: string, status: string, eventTypes: number[]) {
    const queryParams = {
      keyword: keyword || '',
      status: status || '',
      eventTypes: eventTypes ? eventTypes.map(x => x.toString()) : [],
    };

    return this.http.get<any>(`${this.endpoint}/search`, { params: queryParams });
  }

  stopEvent(code: string) {
    return this.http.post<any>(`${this.endpoint}/${code}/stop`, {});
  }

  pauseEvent(code: string) {
    return this.http.post<any>(`${this.endpoint}/${code}/pause`, {});
  }

  generatePdf(code: string, model: any, fileName: string): Observable<boolean> {
    return this.http.post(`${this.endpoint}/${code}/pdf`, model,
      {
        headers: {
          Accept: 'image/*,application/pdf,*/*'
        },
        responseType: 'blob'
      }).pipe(
        mergeMap((data: Blob) => {
          this.downloadBlob(data, fileName);

          return of(true);
        })
      );
  }

  getReportCauses(): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}/report-causes`);
  }

  reportEvent(code: string, model: any) {
    return this.http.post<any>(`${this.endpoint}/${code}/reports`, model);
  }

  /**
   * Generic tigger saveAs browser modal
   *
   * @protected
   * @param {string} url
   * @memberof BaseService
   */
  downloadBlob(blob: Blob, fileName: string) {
    // const blob = new Blob([data], { type: type });
    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = objectUrl;
    link.download = fileName;
    link.click();
    // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    // Remove link
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(objectUrl);
      link.remove();
    }, 100);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/events`;
  }
}
