export const environment = {
  production: true,
  environment: 'demo',
  apiUrl: 'https://demo-api.giftshare.ro/api/v1',
  blogUrl: 'https://blog.giftshare.ro',
  contentUrl: 'https://demo-content.giftshare.ro',
  frontEndUrl: 'https://demo.giftshare.ro',
  paymentUrl: 'https://sandboxsecure.mobilpay.ro',
  pdfLogoUrl: '/assets/images/icons/giftshare-logo-pdf.jpg',
  userDefaultImageUrl: '/assets/images/icons/giftshare_icon_latest.jpg',
  outlookId: '17f129d5-5d98-4b15-a52c-518e5647e775',
  googleApiKey: 'AIzaSyDBhaF_SxzHhttlmFs-47oZ9wSRHqhAs_0',
  googleClientId: '243437698018-uj0qgvobfcj9v9ojrg2ptoq9jah3k727.apps.googleusercontent.com',
  facebookAppId: '157094177262831',
  facebookApiVersion: 'v19.0',

  charityCategoryId: 4,
  giftSharePartnerId: 73,
  googleAnalytics: {
    trackingId: '',
    conversionId: '',
    conversionGroups: {
      registration: '',
      giftcardContact: '',
      corporateContact: '',
      giftcardOrder: '',
    }
  },
  googleRecaptcha: {
    siteKey: '6Le-r3sqAAAAACozSGfBjco5Vmmqc7OUfZyq6X4l'
  },
  sentryConfig: {
    dsn: 'https://3d0559135795436cbeff123fae0373af@o161420.ingest.sentry.io/1227487'
  }
};
