
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

// export function roundNumber(value: any, decimalPlaces: number = 2): number {
// 	return +(Math.round(value + "e" + decimalPlaces)  + "e-" + decimalPlaces );
//   //return Math.round((value + Number.EPSILON) * 100) / 100
// }

//https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary#answer-48764436
export function roundNumber(value: number, decimalPlaces: number = 2) {
  const p = Math.pow(10, decimalPlaces || 0);
  const n = (value * p) * (1 + Number.EPSILON);
  return Math.round(n) / p;
}
