
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Deposit } from '../../core/models/deposit.model';

@Injectable({
  providedIn: 'root',
})
export class DepositService {

  constructor(private http: HttpClient) {
  }

  getDeposit(depositId: string): Observable<Deposit> {
    return this.http.get<Deposit>(`${this.endpoint}/${depositId}`);
  }

  createDeposit(deposit: Deposit) {
    return this.http.post<Deposit>(`${this.endpoint}`, deposit);
  }

  private get endpoint(): string {
    return `${environment.apiUrl}/deposits`;
  }
}
